import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';

let maxDate = new Date();
let tokenItem;
export class ApplicationWiseToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tokenInfo: {},
            edumanTokenInfo: { numberOfToken: 0 },
            netiworldTokenInfo: { numberOfToken: 0 },
            startDate: '',
            errors: {},
            endDate: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0
        }

        this.managementPointService = new ManagementPointService();
    }

    onChangeFromDate = (e) => {
        let { startDate, errors } = this.state;
        errors["fromDate"] = "";
        startDate = e.target.value
        this.setState({ startDate, errors });
    }

    onChangeToDate = (e) => {
        let { endDate, errors } = this.state;
        errors["toDate"] = "";
        endDate = e.target.value
        this.setState({ endDate, errors });
    }

    onSearchApplicationWiseToken = () => {

        if (this.onSearchFormError()) {
            let fromDate = NetiDateUtils.getDateFromString(this.state.startDate);
            let toDate = NetiDateUtils.getDateFromString(this.state.endDate);

            let problemDateRange = {
                startDate: fromDate,
                endDate: toDate
            }
            localStorage.setItem("problemDateRange", JSON.stringify(problemDateRange));

            if (Date.parse(toDate) >= Date.parse(fromDate)) {
                this.setState({ topProgressBar: true, dataTableIsLoading: true });
                this.managementPointService.getTokenListByApplication(fromDate, toDate)
                    .then(res => {
                        if (res.status == 302) {
                            return res.json().then((body) => {

                                // console.log('body', body);

                                if (body.length === 0) {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: "No Records Found", topProgressBar: false, dataTableIsLoading: false })
                                } else {
                                    body.map(item => {

                                        if (item.module === 'Eduman') { this.setState({ edumanTokenInfo: item }); }
                                        if (item.module === 'NetiWorld') { this.setState({ netiworldTokenInfo: item }); }

                                    });
                                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                                }
                            })
                        }
                        else {
                            this.managementPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                                });
                        }
                    }).catch((error) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
                    });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "To Date can't be earlier than From Date." });
            }
        }
    }

    onSearchFormError = () => {
        let { errors, startDate, endDate } = this.state;
        let formIsValid = true;

        if (startDate === '') {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        if (endDate === '') {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, edumanTokenInfo, netiworldTokenInfo } = this.state;

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="nw-form">
                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={this.state.startDate}
                                        onChange={this.onChangeFromDate}
                                        showIcon={true}
                                        dateFormat="dd/mm/yy"
                                        name="fromDate"
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["fromDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={this.state.endDate}
                                        onChange={this.onChangeToDate}
                                        showIcon={true}
                                        dateFormat="dd/mm/yy"
                                        name="toDate"
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["toDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button-primary nw-button nw-button-center"
                                    icon="fas fa-search"
                                    onClick={this.onSearchApplicationWiseToken}
                                />
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="nw-data-table">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <Panel header="Application Wise Token Info">

                                <div className="p-grid">
                                    <div className="card p-col-12 p-lg-12 p-xl-6">
                                        <center>
                                            <h2 className="text-bold">Eduman </h2>
                                            <Link style={{ "font-size": "40px" }} to={{ pathname: MANAGEMENT_POINT.MODULE_WISE_TOKEN, tokenInfo: this.state.edumanTokenInfo, startDate: this.state.startDate, endDate: this.state.endDate }} >
                                                <u>{edumanTokenInfo.numberOfToken.toLocaleString('EN-IN')}</u>
                                            </Link>
                                            {edumanTokenInfo.numberOfToken ? <p>Number of Token</p> : ''}
                                        </center>
                                    </div>

                                    <div className="card p-col-12 p-lg-12 p-xl-6">
                                        <center>
                                            <h2 className="text-bold">Netiworld</h2>
                                            <Link style={{ "font-size": "40px" }} to={{ pathname: MANAGEMENT_POINT.MODULE_WISE_TOKEN, tokenInfo: this.state.netiworldTokenInfo, startDate: this.state.startDate, endDate: this.state.endDate }}>
                                                <u>{netiworldTokenInfo.numberOfToken.toLocaleString('EN-IN')}</u>
                                            </Link>
                                            {netiworldTokenInfo.numberOfToken ? <p>Number of Token</p> : ''}
                                        </center>
                                    </div>
                                </div>

                            </Panel>
                        </div>

                    </div>

                </div>

            </div>

        )
    }
}