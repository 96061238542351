import React, { Component } from 'react';
import { BankAccountInfo } from './BankAccountInfo';
import { BankBranchInfo } from './BankBranchInfo';
import { TabView, TabPanel } from 'primereact/tabview';

export class CoreBankInfo extends Component {
    
    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                        <TabView >
                            <TabPanel header="Bank Account">
                                <BankAccountInfo />
                            </TabPanel>
                            <TabPanel header="Bank Branch">
                                <BankBranchInfo />
                            </TabPanel>
                        </TabView>
                    </div>

                </div>
            </div>
        )
    }
}