import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// custom imports
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
// import { NetiCMSSetupProgress } from '../../common/NetiCMSSetupProgress';
import { ErrorMessageView } from '../../common/ErrorMessageView';

let cmsId = 0;

export class ServiceChargeConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            dataTableValue: [],
            dialogVisible: false,

            requestedBodyObj: {
                "cmsId": 0,
                "coreConfigId": 0,
                "serviceCharge": 0
            },
            selectedRowData: '',

        }

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            cmsId = urlId;
            this.setState({ homeReturnButton: false });
            this.getAdmisiaCoreConfigTableData();
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    getAdmisiaCoreConfigTableData() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getAdmisiaAllCoreConfigList()
            .then(res => {

                // console.log('res-body', res);
                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('body', body);

                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                        } else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });

    }

    configDateBody(rowData) {
        return NetiDateUtils.getDDhypMMhypYY(rowData.configDate);
    }

    bodyActionTemplate = (rowData) => {
        return <Button
            type="button"
            icon="fas fa-pencil-alt"
            className="nw-action-button"
            tooltip="Update"
            onClick={(e) => this.setRowDataToUpdateDialog(rowData)}
        />
    }

    setRowDataToUpdateDialog = (rowData) => {

        let requestedBodyObj = {
            "cmsId": rowData.cmsId,
            "coreConfigId": rowData.coreConfigId,
            "serviceCharge": rowData.serviceCharge
        }

        this.setState({ requestedBodyObj: requestedBodyObj, selectedRowData: rowData });
        this.setState({ dialogVisible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();

    }

    onHideDialog = () => { this.setState({ dialogVisible: false }); this.resetDialogValue(); this.CommonFuctionality.blurDialogBackgroundDeActive(); }

    resetDialogValue = () => {

        let requestedBodyObj = {
            "cmsId": 0,
            "coreConfigId": "",
            "serviceCharge": '',
        }

        this.setState({ errors: {} });
        this.setState({ requestedBodyObj: requestedBodyObj, });

    }

    onChageInputValue = (e) => {

        let { requestedBodyObj } = this.state;
        requestedBodyObj[e.target.name] = e.target.value;
        this.setState({ requestedBodyObj })
        this.clearErrorMsg(e.target.name);
    }

    clearErrorMsg = (name) => {
        let { errors } = this.state;
        errors[name] = '';
        this.setState({ errors })
    }

    onSubmitHandler = () => {
        let { requestedBodyObj } = this.state;
        // console.log('onSubmitHandler', requestedBodyObj);

        if (this.emptyHandleError()) {

            let { errors } = this.state;
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.DwsService.admisiaUpdateServiceCharge(requestedBodyObj)
                .then(res => {
                    console.log('update-res', res);
                    if (res.status == 202) {
                        return res.json().then((body) => {
                            // console.log('res.status', body, body.messageType);
                            if (body.messageType === 1) {
                                this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });
                                this.setState({ topProgressBar: false });
                                this.onHideDialog();
                                this.getAdmisiaCoreConfigTableData();
                            } else {
                                this.growl.show({ severity: 'error', summary: 'Error', detail: body.message });
                                this.setState({ topProgressBar: false });
                            }
                        });;
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }

                })
                .catch(error => {
                    this.setState({ errors: errors })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });

        }
    }

    emptyHandleError = () => {

        let { requestedBodyObj, errors } = this.state;
        let formIsValid = true;

        if (requestedBodyObj.serviceCharge === '' || requestedBodyObj.serviceCharge === null) {
            formIsValid = false;
            errors["serviceCharge"] = "Service Charge can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;

    }

    render() {

        let { requestedBodyObj, dataTableValue, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Core Config List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        return (

            <div className="p-fluid">
                {/* <NetiCMSSetupProgress /> */}

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="urlName" header="URL Name" filter={true} />
                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                    <Column field="instituteAddress" header="Institute Address" filter={true} />
                                    <Column field="instituteContact" header="Institute Contact" filter={true} />
                                    <Column field="academicYear" header="Academic Year" filter={true} />
                                    <Column field="serviceCharge" header="Service Charge" filter={true} />
                                    <Column field="configDate" header="Config Date" body={this.configDateBody} filter={true} />
                                    <Column field="action" header="Action" body={this.bodyActionTemplate} />
                                </DataTable>

                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header="Update Service Charge"
                        visible={this.state.dialogVisible}
                        onHide={this.onHideDialog}
                        closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">URL Name</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.selectedRowData.urlName}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Institute Name</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.selectedRowData.instituteName}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Academic Year</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.selectedRowData.academicYear}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Service Charge<span>*</span></span>
                                                <InputText
                                                    keyfilter="num"
                                                    placeholder="Enter Service Charge"
                                                    name="serviceCharge"
                                                    value={requestedBodyObj.serviceCharge}
                                                    onChange={e => this.onChageInputValue(e)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["serviceCharge"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">(<span>*</span>) required fields</div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                label="Update"
                                                icon="fas fa-check"
                                                onClick={() => this.onSubmitHandler()}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>

            </div>
        )
    }
}