import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';
import { SaCoreSettingsService } from '../../../service/saPoint/SaCoreSettingsService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Growl } from "primereact/growl";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';

let totalDueAmount;
export class TeacherPayroll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            academicYearErrorMessave: null,
            academicYear: null,
            academicYears: [],
            payrollInfoList: {
                detailList: [],
            },
            academicYearFiltering: false,
            errors: {},
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,
        };

        this.teacherPortal = new TeacherPortal();
        this.saCoreSettingsService = new SaCoreSettingsService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.fetchAcademicYearList();
        this.setCurrentAcademicYearToDropDown();
    }

    setCurrentAcademicYearToDropDown = () => {
        let academicYear = NetiDateUtils.getYear(new Date());
        this.setState({ academicYear })
    }

    fetchAcademicYearList() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.saCoreSettingsService.getGlobalInfoByTypeId(2101).then(data => {
            if (data.item) {
                this.setState({ academicYears: data.item, topProgressBar: false, firstDropdownIsLoading: false });
            } else {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No data fund' })
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    onSubmitHandler = () => {

        if (this.onSubmitErrorHandler()) {
            if (this.props.teacherBasicInfo && this.props.teacherBasicInfo.item) {
                if (this.state.academicYear != null) {

                    this.setState({ academicYearErrorMessave: false })
                    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false })

                    this.teacherPortal.getYearWiseStaffPayroll(this.props.hrInstitute, this.props.teacherBasicInfo.item.staffId, this.state.academicYear)
                        .then(data => {

                            if (data.item) {
                                this.setState({ payrollInfoList: data.item, errors: {} })
                                this.getTotalDueAmount();
                                this.setState({ dataTableIsLoading: false, topProgressBar: false })
                            } else {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No data found.' })
                            }

                        }).catch(
                            error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
                        );
                }
                else { this.setState({ academicYearErrorMessave: true }) };
            }

        }

    }


    onSubmitErrorHandler = () => {
        let formIsValid = true;
        let { errors } = this.state;

        if (!this.state.academicYear) {
            formIsValid = false;
            errors['academicYear'] = "Academic Year Can't left empty."
        }

        this.setState({ errors })
        return formIsValid;
    }

    onAcademicYearFilter = () => {
        if (this.state.academicYearFiltering) this.setCurrentAcademicYearToDropDown();
        this.setState({ academicYearFiltering: !this.state.academicYearFiltering });
    }

    getTotalDueAmount = () => {
        totalDueAmount = 0;
        if (this.state.payrollInfoList.detailList) {
            this.state.payrollInfoList.detailList.map(item => {
                totalDueAmount += item.dueAmount;
            })

            return totalDueAmount;
        }
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let parollTableContent = '';
        let academicYearList = []
        academicYearList = this.state.academicYears.map((item) => ({
            value: item.name,
            label: item.name,
        }));

        let headervalue = (
            <div className="p-clearfix">
                Payroll List{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {this.state.payrollInfoList.detailList.length}{" "}
                </span>
            </div>
        );

        if (this.state.payrollInfoList) {

            parollTableContent =
                <DataTable
                    value={this.state.payrollInfoList.detailList}
                    header={headervalue}
                    responsive={true}
                    paginator={true}
                    rows={10}
                    style={{ textAlign: 'center' }}
                >
                    <Column field="invoiceId" header="Invoice ID" filter={true} />
                    <Column field="month" header="Month" filter={true} />
                    <Column field="paidStatus" header="TXN Type" filter={true} />
                    <Column field="payableAmount" header="Payable Amount" filter={true} />
                    <Column field="paidAmount" header="Paid Amount" filter={true} />
                    <Column field="dueAmount" header="Due Amount" filter={true} />
                </DataTable>

        }

        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>

        return (
            <div className="p-col-12 p-col-nogutter">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid ">
                    <div className="p-col-12">
                        {!this.state.academicYearErrorMessave ? null :
                            <div className="p-messages p-component p-messages-warn" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                <div className="p-messages-wrapper">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                            <span className="p-messages-detail">
                                                Please Select Year
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="p-col-12">
                        <div className="nw-form-body">

                            <div className="p-col-12">
                                <div className="p-md-3 p-md-offset-9 p-col-7 p-offset-5">
                                    <Button
                                        label="Academic Year"
                                        icon="fas fa-filter"
                                        iconPos="right"
                                        onClick={this.onAcademicYearFilter} />
                                </div>
                            </div>

                            <div className="p-col-12">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">{requiredSing}Academic Year</span>
                                        {this.state.academicYearFiltering ?
                                            <Dropdown
                                                options={academicYearList}
                                                placeholder="Select Academic Year"
                                                value={this.state.academicYear}
                                                onChange={event => this.setState({ academicYear: event.value })}
                                                autoWidth={false} />
                                            :
                                            <Dropdown
                                                disabled
                                                options={academicYearList}
                                                placeholder="Select Academic Year"
                                                value={this.state.academicYear}
                                                onChange={event => this.setState({ academicYear: event.value })}
                                                autoWidth={false} />
                                        }
                                    </div>
                                }
                                <span className="error-message"> {this.state.academicYear ? '' : this.state.errors['academicYear']} </span>
                            </div>

                            <div className="p-col-12 nw-button-parent">
                                <div className="required-field">
                                    (<span>*</span>) required fields
                                    </div>

                                <Button
                                    label="Search"
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    icon="fas fa-search"
                                    iconPos="right"
                                    onClick={this.onSubmitHandler.bind(this)} />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="">
                    <div className="p-col-12">
                        <span><b>Due Amount :</b> {totalDueAmount ? totalDueAmount : '0'}</span>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {parollTableContent}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}