import { BaseURIHolder } from "../../utils/BaseURIHolder";
import AuthService from "../../auth/AuthService";

export class PurchaseService {
  constructor() {
    this.baseURIHolder = new BaseURIHolder();
    this.Auth = new AuthService();
  }

  checkOfferProductByCode(checkCode) {
    let uri =
      this.baseURIHolder.getUser() + "/product/offer/by/code?code=" + checkCode;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  submitOfferProductByCode(offerProductInfo) {
    let uri = this.baseURIHolder.getUser() + "/product/purchase/offer";
    return this.Auth.postFetch(uri, offerProductInfo).catch((error) =>
      console.log("error", error)
    );
  }

  submitPurchaseGeneralProduct(generalProduct) {
    let uri = this.baseURIHolder.getUser() + "/product/purchase";
    return this.Auth.postFetch(uri, generalProduct).catch((error) =>
      console.log("error", error)
    );
  }
  fetchGeneralPurchaseProductListTopTen() {
    let uri = this.baseURIHolder.getUser() + "/products/purchases/by/user";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchGeneralPurchaseProductListByDateRange(fromDate, toDate) {
    let uri =
      this.baseURIHolder.getUser() +
      "/product/purchases/by/date-range"
        .concat("?startDate=")
        .concat(fromDate)
        .concat("&endDate=")
        .concat(toDate);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  fetchTopTenOfferProduct() {
    let uri = this.baseURIHolder.getUser() + "/product/offer/top10";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchProductPurchaseCodeDetailsByPurchaseId(purchaseProductId) {
    let uri =
      this.baseURIHolder.getUser() +
      "/purchase/codes/by/purchaseid"
        .concat("?purchaseID=")
        .concat(purchaseProductId);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  fetchOfferProductByDate(dateRangeObj) {
    let uri = this.baseURIHolder.getUser() + "/product/purchases/by/date-range";
    return this.Auth.getFetch(uri, dateRangeObj);
  }

  fetchOfferProductListByDateRange(dateRangeParamFormat) {
    let uri = this.baseURIHolder.getUser() + "/product/offer/by/date-range";
    return this.Auth.postFetch(uri, dateRangeParamFormat).catch((error) =>
      console.log("error", error)
    );
  }

  fetchGeneralProductByUserRole(userRole) {
    let uri =
      this.baseURIHolder.getUser() +
      "/products/by/role".concate("?roleID=").concate(userRole);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchGeneralProduct() {
    let uri = this.baseURIHolder.getUser() + "/products/by/roles";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchPendingDeviceListOfUser(searchObj) {
    let uri = this.baseURIHolder.getUser() + "/pending/device/list";
    return this.Auth.postFetch(uri, searchObj).catch((error) =>
      console.log("error", error)
    );
  }
}
