import React, { Component } from "react";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { CRMPointService } from "./../../../service/crmPoint/CRMPointService";

export class DomainApproval extends Component {

    constructor() {
        super();
        this.state = {
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            errors: {},
            dataTableValue: [],
            dateRangeParamFormat: {
                startDate: "",
                endDate: "",
                pageLimit: 50,
                pageNo:0
            },
            status:'',
            dataTableSelection: null,
        }
        this.CRMPointService = new CRMPointService();
        this.NetiDateUtils = new NetiDateUtils();

    }

    onSubmitHandler = () =>{
        let { dateRangeParamFormat } = this.state
        this.setState({ dataTableValue: [] });
        
        if (this.searchHandleError()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(dateRangeParamFormat.endDate) >= Date.parse(dateRangeParamFormat.startDate)) {
                this.CRMPointService.getDomainAndHostingListByStatus(this.state.status,"domain",this.state.dateRangeParamFormat).then(res => {
                    if (res.status === 302) {
                        return res.json().then(body => {
                            console.log("body",body);
                            
                            for (let i = 0; i < body.length; i++) {
                                if (body[i].domainhostingOrderDate == null) {
                                    body[i].domainhostingOrderDate = '';
                                } else {
                                    body[i].domainhostingOrderDate = NetiDateUtils.getAnyShortForm(body[i].domainhostingOrderDate, 'DD-MMM-YYYY');
                                }
                            }
                            for (let k = 0; k < body.length; k++) {
                                if (body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate == null) {
                                    body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate = '';
                                } else {
                                    body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate = NetiDateUtils.getAnyShortForm(body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                                }
                            }
                            for (let j = 0; j < body.length; j++) {
                                if (body[j].domainhostingOrderStatus === 0) {
                                    body[j].domainhostingOrderStatus = 'Pending';
                                } 
                            }
                            this.setState({ dataTableValue: body });
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        });
                    } else {
                        this.PartnerPointService.Auth.handleErrorStatus(res).then(respon => {
                            this.setState({
                                topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: respon
                            });
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Unable to load table"
                    });
                });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be greater than To Date." });
                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: false })
            }
        }
        
        
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }


    onChangeStartDate = (e) => {
        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);
    }

    onChangeEnableStatus(e){
        let { errors } = this.state;
        this.setState({ status: e.target.value});
        errors["requestEnableStatus"] = "";
    }


    searchHandleError = () => {
        let { errors, dateRangeParamFormat } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (dateRangeParamFormat.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "From Date can't left empty.";
        }
        if (dateRangeParamFormat.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "To Date can't left empty.";
        }
        if (this.state.status === '') {
            formIsValid = false;
            errors["requestEnableStatus"] = "Status can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

     orderStatus(rowData){
         if (rowData["domainhostingOrderStatus"] === 1) {
            return <div>Approve</div>
         }else if(rowData["domainhostingOrderStatus"] === 2){
            return <div style={{ color:'red' }}>Reject</div>
         }
     }
     actionDetails = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                tooltip="Details"
                // onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { dateRangeParamFormat,dataTableValue } = this.state
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Domain Approve List <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;
        const statusOptions = [
            {label:'Approve', value:1},
            {label:'Reject', value:2}
        ]

        return (
            <div className="p-fluid">
                    
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <Growl ref={(el) => this.growl = el} />
                            {topProgressBar ?
                                <ErrorMessageView
                                    topProgressBar={topProgressBar}
                                />
                                : null
                            }
                            {errorMsgVisible ?
                                <ErrorMessageView
                                    errorMsgVisible={errorMsgVisible}
                                    errorMsgBody={errorMsgBody}
                                />
                                : null
                            }
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">From <span>*</span></span>
                                    <Calendar
                                        name='startDate'
                                        value={dateRangeParamFormat.startDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["startDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">To <span>*</span></span>
                                    <Calendar
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        name='endDate'
                                        value={dateRangeParamFormat.endDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        dateFormat='dd/mm/yy'
                                        yearRange="2010:2030"
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["endDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Status <span>*</span></span>
                                    <Dropdown
                                        placeholder="Select Status"
                                        name="requestEnableStatus"
                                        options={statusOptions}
                                        value={this.state.status}
                                        onChange={(e) => this.onChangeEnableStatus(e)}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["requestEnableStatus"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table nw-data-table-tabview">
                            <DataTable
                                value={this.state.dataTableValue}
                                selectionMode="single"
                                header={tableHeader}
                                selection={this.state.dataTableSelection}
                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                responsive={true}
                                paginator={true}
                                rows={10}
                            >
                                <Column field="domainhostingOrderDate" header="Order Date" filter={true}  />
                                <Column field="domainhostingOrderStatus" header="Order Status" filter={true} body={this.orderStatus}  />
                                <Column field="productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO.productName" header="Product Name" filter={true} />
                                <Column field="productPurchaseCodeDTO.purchaseCode" header="Purchase Code" filter={true} />
                                <Column field="productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate" header="Purchase Date" filter={true} />
                                <Column field="domainName" header="Desire URL" filter={true} />
                                {/* <Column field="action" header="Action" style={{ width: "80px" }} body={this.actionDetails} /> */}

                            </DataTable>
                              
                            </div>
                        </div>
                    </div>

            </div>
        )
    }
}