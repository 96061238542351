


import AuthService from '../../auth/AuthService';
import { BaseURIHolder } from '../../utils/BaseURIHolder';

export class EdumanGlobalInfoService {

    constructor() {
        this.state = {   
        }
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService()
    }

    
    // fetchTypeInfo() {
    //     let uri = this.baseURIHolder.getUser() + 'api gose here'; 
    //     return this.Auth.getFetch(uri)
    //         .then(function (response) {
    //             if (response.status === 302 || response.status === 200) {
    //                 return response.json()
    //             } else {
    //                 return this.state.typeInfo;
    //                 console.log('No date found this Redirect Api ', uri);
    //             }
    //         })
    //         .catch(error => console.log('responseErrorData:::' + error));
    // }

    fetchAllCoreTypes() {
        let uri = this.baseURIHolder.getUser() + `/cs/global-info/type/find-all`
        return this.Auth.getFetch(uri)
        .catch((error) => {console.log(error)})
    }

    fetchIndividualTypesByTypeId(typeId) {
        let uri = this.baseURIHolder.getUser() + `/cs/global-info/list/find-all?typeId=${typeId}`
        return this.Auth.getFetch(uri)
        .catch((error) => {console.log(error)}) 
    }
}