import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';
import UnAuthService from '../../auth/UnAuthService';


export class TokenService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth=new AuthService();
        this.unAuth = new UnAuthService();
    }

    getUserTokenByDefaultCategory(catDefaultCode){
        let uri = this.baseURIHolder.getUser() + '/tokens/modules/by?catDefaultCode=' + catDefaultCode;
        console.log('uri',uri);
        
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserTokenByParentCategory(parentCategoryID){
        let uri = this.baseURIHolder.getUser() + '/tokens/types/by?parentCategoryID=' + parentCategoryID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    addNetiworldToken(tokenDetails){
        let uri = this.baseURIHolder.getUser() + '/tokens/new';
        return this.Auth.postFetch(uri,tokenDetails)
            .catch((error) => console.log('error', error)); 
    }

    getUserTopTenTokens(){
        let uri = this.baseURIHolder.getUser() + '/tokens/by/top10';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPendingTokenList(){
        let uri = this.baseURIHolder.getUser() + '/tokens/by/pending';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    saveUserRating(ratingObj){
        let uri = this.baseURIHolder.getUser() + '/tokens/rating';
        return this.Auth.putFetch(uri,ratingObj)
            .catch((error) => console.log('error', error)); 
    }


}