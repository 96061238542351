import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';
import { SaCoreSettingsService } from '../../../service/saPoint/SaCoreSettingsService';
import { Panel } from 'primereact/panel';
import { Growl } from "primereact/growl";
import { ErrorMessageView } from '../../common/ErrorMessageView';


let errors = {};
export class TeacherRoutine extends Component {

    constructor() {
        super();
        this.state = {

            shiftList: '',
            shiftId: '',
            errors: {},
            teacherRoutineInfo: [],
            dataTableValue: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,

        };

        this.onChangeShift = this.onChangeShift.bind(this);
        this.teacherPortal = new TeacherPortal();
        this.saCoreSettingsService = new SaCoreSettingsService();
    }

    componentWillMount() {
        this.fetchStaffShiftList()
    }

    fetchStaffShiftList() {
        if (this.props.teacherBasicInfo.item) {
            this.setState({ firstDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
            this.teacherPortal.fetchStaffShiftList(this.props.hrInstitute, this.props.teacherBasicInfo.item.staffId).then(res => {
                if (res.item) {
                    this.setState({ shiftList: res.item });
                    this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                } else {
                    this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Something went wrong.' })
                }
            }).catch(
                error => this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
            );
        }
    }

    onSubmitHandler = () => {

        if (this.handleError()) {
            this.teacherPortal.getStaffRoutine(this.props.hrInstitute, this.state.shiftId, this.props.teacherBasicInfo.item.staffId)
                .then(data => {
                    this.setState({
                        teacherRoutineInfo: data,
                        dataTableValue: data.routines
                    })
                }

                );
        }
    }

    onChangeShift = (e) => {
        errors["shift"] = "";
        this.setState({ shiftId: e.value, errors: errors })
    }

    handleError() {

        errors = {};
        let formIsValid = true;

        if (this.state.shiftId === '') {
            formIsValid = false;
            errors["shift"] = "Shift can't left empty.";
        }
        else { return formIsValid }
        this.setState({ errors: errors });
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let shiftOptions = [];
        if (this.state.shiftList && this.state.shiftList.length) {
            shiftOptions = this.state.shiftList.map(item => ({
                value: item.shiftId,
                label: item.shiftName
            }));
        }

        let columns = [];

        if (this.state.teacherRoutineInfo) {

            let fisrtPeriodHeader = <b>{this.state.teacherRoutineInfo.firstPeriodName}<br />{this.state.teacherRoutineInfo.firstPeriodTime}</b>
            let secondPeriodHeader = <b>{this.state.teacherRoutineInfo.secondPeriodName}<br />{this.state.teacherRoutineInfo.secondPeriodTime}</b>
            let thirdPeriodHeader = <b>{this.state.teacherRoutineInfo.thirdPeriodName}<br />{this.state.teacherRoutineInfo.thirdPeriodTime}</b>
            let fourthPeriodHeader = <b>{this.state.teacherRoutineInfo.fourthPeriodName}<br />{this.state.teacherRoutineInfo.fourthPeriodTime}</b>
            let fifthPeriodHeader = <b>{this.state.teacherRoutineInfo.fifthPeriodName}<br />{this.state.teacherRoutineInfo.fifthPeriodTime}</b>
            let sixthPeriodHeader = <b>{this.state.teacherRoutineInfo.sixthPeriodName}<br />{this.state.teacherRoutineInfo.sixthPeriodTime}</b>
            let seventhPeriodHeader = <b>{this.state.teacherRoutineInfo.seventhPeriodName}<br />{this.state.teacherRoutineInfo.seventhPeriodTime}</b>
            let eighthPeriodHeader = <b>{this.state.teacherRoutineInfo.eighthPeriodName}<br />{this.state.teacherRoutineInfo.eighthPeriodTime}</b>
            let ninethPeriodHeader = <b>{this.state.teacherRoutineInfo.ninethPeriodName}<br />{this.state.teacherRoutineInfo.ninethPeriodTime}</b>
            let tenthPeriodHeader = <b>{this.state.teacherRoutineInfo.tenthPeriodName}<br />{this.state.teacherRoutineInfo.tenthPeriodTime}</b>

            let elevenPeriodHeader = <b>{this.state.teacherRoutineInfo.elevenPeriodName}<br />{this.state.teacherRoutineInfo.elevenPeriodTime}</b>
            let twelvePeriodHeader = <b>{this.state.teacherRoutineInfo.twelvePeriodName}<br />{this.state.teacherRoutineInfo.twelvePeriodTime}</b>
            let thirteenPeriodHeader = <b>{this.state.teacherRoutineInfo.thirteenPeriodName}<br />{this.state.teacherRoutineInfo.thirteenPeriodTime}</b>
            let fourtheenPeriodHeader = <b>{this.state.teacherRoutineInfo.fourtheenPeriodName}<br />{this.state.teacherRoutineInfo.fourtheenPeriodTime}</b>
            let tiffinPeriodHeader = <b>{this.state.teacherRoutineInfo.tiffinPeriodName}<br />{this.state.teacherRoutineInfo.tiffinPeriodTime}</b>

            let cellValue = (value) => {
                if (value != null) {
                    let temp = value.split('<br/>');
                    return <div>{temp[2]}<br /> {temp[0]}<br />{temp[1]}</div>
                } else {
                    return <div></div>;
                }
            }

            let firstPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.firstPeriodSubject)}</div>;
            }
            let secondPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.secondPeriodSubject)}</div>;
            }
            let thirdPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.thirdPeriodSubject)}</div>;
            }
            let fourthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.fourthPeriodSubject)}</div>;
            }
            let fifthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.fifthPeriodSubject)}</div>;
            }
            let sixthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.sixthPeriodSubject)}</div>;
            }
            let seventhPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.seventhPeriodSubject)}</div>;
            }
            let eighthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.eighthPeriodSubject)}</div>;
            }
            let ninethPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.ninethPeriodSubject)}</div>;
            }
            let tenthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.tenthPeriodSubject)}</div>
            };

            let elevenPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.elevenPeriodSubject)}</div>;
            }
            let twelvePeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.twelvePeriodSubject)}</div>;
            }
            let thirteenPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.thirteenPeriodSubject)}</div>;
            }
            let fourtheenPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.fourtheenPeriodSubject)}</div>
            };
            let tiffinPeriodSubject = (rowData) => {
                return <div><i className='ui-icon-resturent'></i></div>
            };

            columns.push(<Column field="dayName" header="Day/Period" />);


            if (this.state.teacherRoutineInfo.firstPeriodName) columns.push(<Column body={firstPeriodSubject} header={fisrtPeriodHeader} />);
            if (this.state.teacherRoutineInfo.secondPeriodName) columns.push(<Column body={secondPeriodSubject} header={secondPeriodHeader} />);
            if (this.state.teacherRoutineInfo.thirdPeriodName) columns.push(<Column body={thirdPeriodSubject} header={thirdPeriodHeader} />);
            if (this.state.teacherRoutineInfo.fourthPeriodName) columns.push(<Column body={fourthPeriodSubject} header={fourthPeriodHeader} />);
            if (this.state.teacherRoutineInfo.fifthPeriodName) columns.push(<Column body={fifthPeriodSubject} header={fifthPeriodHeader} />);
            if (this.state.teacherRoutineInfo.sixthPeriodName) columns.push(<Column body={sixthPeriodSubject} header={sixthPeriodHeader} />);
            if (this.state.teacherRoutineInfo.seventhPeriodName) columns.push(<Column body={seventhPeriodSubject} header={seventhPeriodHeader} />);
            if (this.state.teacherRoutineInfo.eighthPeriodName) columns.push(<Column body={eighthPeriodSubject} header={eighthPeriodHeader} />);
            if (this.state.teacherRoutineInfo.ninethPeriodName) columns.push(<Column body={ninethPeriodSubject} header={ninethPeriodHeader} />);
            if (this.state.teacherRoutineInfo.tenthPeriodName) columns.push(<Column body={tenthPeriodSubject} header={tenthPeriodHeader} />);

            if (this.state.teacherRoutineInfo.elevenPeriodName) columns.push(<Column body={elevenPeriodSubject} header={elevenPeriodHeader} />);
            if (this.state.teacherRoutineInfo.twelvePeriodName) columns.push(<Column body={twelvePeriodSubject} header={twelvePeriodHeader} />);
            if (this.state.teacherRoutineInfo.thirteenPeriodName) columns.push(<Column body={thirteenPeriodSubject} header={thirteenPeriodHeader} />);
            if (this.state.teacherRoutineInfo.fourtheenPeriodName) columns.push(<Column body={fourtheenPeriodSubject} header={fourtheenPeriodHeader} />);
            if (this.state.teacherRoutineInfo.tiffinPeriodName) columns.push(<Column body={tiffinPeriodSubject} header={tiffinPeriodHeader} />);

        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid ">
                    <div className="p-col-12">
                        <div className="nw-form-body">

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-12 p-xl-12">
                                    <p className="profile-custom-table-header">
                                        Employee Routine
                                        </p>
                                </div>

                                <div className="p-col-12">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Shift <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Shift"
                                                name="shift"
                                                options={shiftOptions}
                                                value={this.state.shiftId}
                                                onChange={this.onChangeShift}
                                                filter={true}
                                                filterBy="label,value"
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message"> {this.state.errors["shift"]} </span>
                                    </div>

                                    <div className="p-col-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            label="Search"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            icon="fas fa-search"
                                            iconPos="right"
                                            onClick={this.onSubmitHandler.bind(this)}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="p-col-12 p-lg-12 p-xl-12">
                        {this.state.teacherRoutineInfo ? (
                            <div className="nw-data-table">
                                <DataTable value={this.state.dataTableValue}
                                    header='Routine'
                                >
                                    {columns}
                                </DataTable>
                            </div>
                        ) : (
                                ""
                            )}
                    </div>
                </div>
            </div>

        );
    }
}