import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { CUSTOM_MESSAGE } from '../../common/CustomMessage';
import CommonFuctionality from '../../common/CommonFuctionality';

let urlId;
let errors = {};
const userUrlInfo = localStorage.getItem('dwsUrlInfo');
export class EventLogList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberStatus: '',
            dataTableValue: [],
            startDate: '',
            endDate: '',
            updateEventInfoDetails: {
                eventDetails: " ",
                eventEndDate: '',
                eventID: '',
                eventStartDate: '',
                eventTitle: "",
                eventType: "",
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            errors: {},
            dataTableIsLoading: false

        }
        this.dwsService = new DwsService();
        this.NetiDateUtils = new NetiDateUtils();
        this.reloadCoreUrlIdFromLocal();
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.viewEventLogInfoList();
        urlId = this.dwsService.getUrlInfoFromLocalStorage();
    }

    viewEventLogInfoList = () => {
        let eventLogInfoList = {
            "coreUrlInfoID": userUrlInfo,
            "pageNo": 0,
            "limit": 10
        }

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.dwsService.getDwsEventLogList(eventLogInfoList)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        for (let i = 0; i < body.length; i++) {
                                body[i].eventStartDate = NetiDateUtils.getAnyShortForm(body[i].eventStartDate, 'DD-MMM-YYYY hh:mm:ss a');
                                body[i].eventEndDate = NetiDateUtils.getAnyShortForm(body[i].eventEndDate, 'DD-MMM-YYYY hh:mm:ss a');
                        }


                        this.setState({ dataTableValue: body });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else if (res.status == 400) {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong.' })
                }
                else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }


    onHide = (event) => {
        this.setState({ visible: false });
        this.commonFuctionality.blurDialogBackgroundDeActive()
    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });
        
    }

    startDateFormat = (rowData) => {
       
    //   return <span>{NetiDateUtils.getAnyShortForm(rowData['eventStartDate'], 'DD-MMM-YYYY')}</span>;
          
    }

    endDateFormat = (rowData) => {

        // return <span>{NetiDateUtils.getAnyShortForm(rowData['eventEndDate'], 'DD-MMM-YYYY')}</span>;
       
    }

    viewDownloadData = (rowData) => {
        this.setState({ visible: true });
        let { updateEventInfoDetails, startDate, endDate } = this.state
        let { coreUrlInfoDTO } = this.state.updateEventInfoDetails

        updateEventInfoDetails.eventID = rowData.eventID
        updateEventInfoDetails.eventTitle = rowData.eventTitle
        updateEventInfoDetails.eventType = rowData.eventType
        updateEventInfoDetails.eventDetails = rowData.eventDetails
        updateEventInfoDetails.eventStartDate = NetiDateUtils.getAnyShortForm(rowData['eventStartDate'], "YYYY-MM-DD")
        updateEventInfoDetails.eventEndDate = NetiDateUtils.getAnyShortForm(rowData['eventEndDate'], "YYYY-MM-DD")
        updateEventInfoDetails.coreUrlInfoDTO.urlInfoID = rowData.coreUrlInfoDTO.urlInfoID;

        this.setState({ 
            updateEventInfoDetails, 
            startDate: NetiDateUtils.getDateFromStringDDMMYYYY(rowData['eventStartDate']),  
            endDate: NetiDateUtils.getDateFromStringDDMMYYYY(rowData['eventEndDate'])
        });
        this.setState({ coreUrlInfoDTO });

        this.commonFuctionality.blurDialogBackgroundActive()

    }

    actionTemplate = (rowData, column) => {

        // let deleteBtn = ''
        // let btnIcon= ''
        // if(rowData.eventStatus === 0){
        //     deleteBtn = "p-button-success";
        //     btnIcon= "fas fa-check"
        // }
        // else if(rowData.eventStatus === 1){
        //     deleteBtn = "p-button-danger";
        //     btnIcon= "fas fa-times-circle"
        // }

        let deleteBtn = ''
        let btnIcon= ''
        let tooltipMsg=''
        if(rowData.eventStatus === 1){
            deleteBtn = "p-button-success";
            btnIcon= "fas fa-check"
            tooltipMsg="Click to Inactive"
        }
        else if(rowData.eventStatus === 0){
            deleteBtn = "p-button-danger";
            btnIcon= "fas fa-minus-circle"
            tooltipMsg='Click to Active'
        }
        
        return <div className="text-center">
                <span style={{ marginRight: '5px' }}>
                    <Button
                        type="button"
                        icon="fas fa-pencil-alt"
                        className="nw-action-button"
                        style={{ marginRight: '.5em', 'fontSize': '1em' }}
                        onClick={(e) => this.viewDownloadData(rowData)}
                        tooltip="Edit"
                    >
                    </Button>
                </span>
                <span>
                    <Button
                        type="button"
                        icon={btnIcon}
                        className={"nw-action-button " + deleteBtn}
                        onClick={(e) => this.deleteEventInfoRowData(rowData)}
                        tooltip={ tooltipMsg }
                    >
                    </Button>
                </span>
                    
            </div>;
    }

    deleteEventInfoRowData = (rowData) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

        this.dwsService.disableEventInfo(rowData.eventID)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: CUSTOM_MESSAGE.SUCCESS_MSG_LONG_UPDATE })

                    setTimeout(() => {
                        this.viewEventLogInfoList();
                        
                    }, 1000);
                    
                } else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
            });

    }

    onChangeEventType = (e) => {
        let { updateEventInfoDetails } = this.state;
        updateEventInfoDetails.eventType = e.target.value;
        this.setState({ updateEventInfoDetails });
    }

    onChangeEventTitle = (e) => {
        let { updateEventInfoDetails, errors } = this.state;
        errors["title"] = ''
        updateEventInfoDetails.eventTitle = e.target.value
        this.setState({ updateEventInfoDetails, errors })
    }

    onChangeEventDetails = (e) => {
        let { updateEventInfoDetails, errors } = this.state;
        errors["details"] = ''
        updateEventInfoDetails.eventDetails = e.target.value
        this.setState({ updateEventInfoDetails, errors })
    }

    onChangeStartDate = (e) => {
        let { updateEventInfoDetails, errors } = this.state;
        errors["startDate"] = ''
        updateEventInfoDetails.eventStartDate = e.target.value;
        this.setState({ updateEventInfoDetails, startDate: updateEventInfoDetails.eventStartDate, errors});
    }

    onChangeEndDate = (e) => {
        let { updateEventInfoDetails, errors } = this.state;
        errors["endDate"] = ''
        updateEventInfoDetails.eventEndDate = e.target.value;
        this.setState({ updateEventInfoDetails, endDate: updateEventInfoDetails.eventEndDate, errors });
    }

    handleError = () => {
        let { updateEventInfoDetails } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });

        if (updateEventInfoDetails.eventTitle === '') {
            formIsValid = false;
            errors["title"] = "Title can't left empty.";
        }
        if (updateEventInfoDetails.eventDetails === '') {
            formIsValid = false;
            errors["details"] = "Details can't left empty.";
        }
        if (updateEventInfoDetails.eventStartDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start date can't left empty.";
        }
        if (updateEventInfoDetails.eventEndDate === '') {
            formIsValid = false;
            errors["endDate"] = "End date can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }

    updateEventInfo = (event, props) => {
        let { updateEventInfoDetails, startDate, endDate } = this.state;

        console.log("updateEventInfoDetails", updateEventInfoDetails);
        
        
        
        console.log("updateEventInfoDetailsChanged", updateEventInfoDetails);

        // if(!startDate){
        //     updateEventInfoDetails.eventStartDate = NetiDateUtils.getDateFromString(updateEventInfoDetails.eventStartDate, 'DD/MM/YYYY')
        //     // console.log("noticeCreateDate", noticeInfo.noticeCreateDate);
        //     this.setState({
        //         updateEventInfoDetails,
        //         startDate: updateEventInfoDetails.eventStartDate
        //     })
        // }
        // else{
        //     updateEventInfoDetails.eventStartDate = updateEventInfoDetails.eventStartDate;
        // }

        // if(!endDate){
        //     // console.log("noticeExpiryDate", this.props.noticeInformation.noticeExpiryDate);
        //     updateEventInfoDetails.eventEndDate = NetiDateUtils.getDateFromString(updateEventInfoDetails.eventEndDate, 'DD/MM/YYYY')
        //     this.setState({
        //         updateEventInfoDetails,
        //         endDate: updateEventInfoDetails.eventEndDate
        //     })
        // }
        // else{
        //     updateEventInfoDetails.eventEndDate = updateEventInfoDetails.eventEndDate;
        // }


        if (this.handleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.setState({ updateEventInfoDetails });
            this.dwsService.updateEventInfo(updateEventInfoDetails)
                .then(res => {

                    if (res.status === 202) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({
                            severity: "success", summary: "Success Message", detail: "Successfully Updated", life: 800
                        });
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                        this.viewEventLogInfoList();
                    } else {
                        this.dwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }
    }

    eventLogSelector = (eventType) => {
        return (
            <div className="input-radio-button">
                <div className="radio-button-inside">
                    <RadioButton
                        value="Holiday"
                        inputId="rb1"
                        checked={eventType === "Holiday"}
                        onChange={this.onChangeEventType}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Holiday</label>
                </div>

                <div className="radio-button-inside">
                    <RadioButton
                        value="Exam Day"
                        inputId="rb2"
                        checked={eventType === "Exam Day"}
                        onChange={this.onChangeEventType}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Exam Day</label>
                </div>

                <div className="radio-button-inside">
                    <RadioButton
                        value="Event"
                        inputId="rb3"
                        onChange={this.onChangeEventType}
                        checked={eventType === "Event"}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Event</label>
                </div>
            </div>
        )
    }


    render() {
        let { errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { updateEventInfoDetails } = this.state
        let { dataTableValue } = this.state;
        let title = <span className="text-center"><span>Update Event Information</span></span>

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Event Info List
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length}
                                </a>
                            </div>
                        </div>;
                        
        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="blur-section">
                    {this.state.dataTableIsLoading ?
                        this.NetiContentLoader.MyPointTableLoader() :
                        <div className="nw-data-table">
                            <Growl ref={(el) => this.growl = el} />
                            <DataTable
                                value={dataTableValue}
                                selectionMode="single"
                                header={tableHeader}
                                selection={this.state.dataTableSelection}
                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                responsive={true}
                                rows={10}
                                columnResizeMode="fit"
                                paginator={true} 
                                // paginatorLeft={paginatorLeft} 
                                // paginatorRight={paginatorRight}
                            >
                                <Column field="eventType" header="Type" filter={true} />
                                <Column field="eventTitle" header="Title" filter={true} />
                                <Column field="eventStartDate" header="Start Date" filter={true} />
                                <Column field="eventEndDate" header="End Date" filter={true} />
                                <Column field="action" header="Action" body={this.actionTemplate} style={{ width: '8em' }} />
                            </DataTable>
                        </div>
                    }
                </div>
                <div className="dialog-section">
                    <Dialog className="nw-dialog" header={title} visible={this.state.visible} onHide={this.onHide} closable>

                        <div className="p-fluid">
                            <div className="p-grid">



                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon addon-no-style">Type <span>*</span></span>
                                                        <div className="nw-inputgroup-desc desc-no-style">{this.eventLogSelector(updateEventInfoDetails.eventType)}</div>
                                                    </div>
                                                </div>
                                                
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Title <span>*</span></span>
                                                        <InputText
                                                            value={updateEventInfoDetails.eventTitle}
                                                            placeholder="Enter Title"
                                                            style={{ width: "100%" }}
                                                            type="text"
                                                            onChange={(e) => this.onChangeEventTitle(e)}
                                                        />
                                                    </div>
                                                    <span className="error-message">{errors["title"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                                        <InputTextarea
                                                            placeholder="Enter fee details"
                                                            rows={3}
                                                            cols={30}
                                                            autoResize={true}
                                                            onChange={(e) => this.onChangeEventDetails(e)}
                                                            value={updateEventInfoDetails.eventDetails}
                                                        />
                                                    </div>
                                                    <span className="error-message">{errors["details"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                                        <Calendar
                                                            value={this.state.startDate}
                                                            placeholder="Select Start Date"
                                                            showIcon={true}
                                                            name="startDate"
                                                            onChange={this.onChangeStartDate}
                                                            yearRange="2010:2030"
                                                            dateFormat="dd/mm/yy"
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            dateOnly="true"
                                                            showButtonBar={true}
                                                        />
                                                    </div>
                                                    <span className="error-message">{errors["startDate"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                                        <Calendar
                                                            value={this.state.endDate}
                                                            showIcon={true}
                                                            name="endDate"
                                                            onChange={this.onChangeEndDate}
                                                            yearRange="2010:2030"
                                                            dateFormat="dd/mm/yy"
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            dateOnly="true"
                                                            showButtonBar={true}
                                                            placeholder="Select End Date"
                                                        />
                                                    </div>
                                                    <span className="error-message">{errors["endDate"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        onClick={this.onHide}
                                                    />

                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        label="Update"
                                                        onClick={this.updateEventInfo}
                                                        icon="fas fa-check"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>

        );
    }
}