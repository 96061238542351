import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Paginator } from 'primereact/paginator';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadLandscape_TableWithSubTitle, pdfDownloadLandscape_Two_TableWithSubTitle } from '../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';

export class MonthlyStatement extends Component {

    constructor(props) {
        super(props);
        this.state = {

            monthlyStatementList: [],
            allInstituteMonthlyBillList: [],
            yearValue: '',
            monthValue: '',
            tableView: false,
            errors: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            secondDataTableIsLoading: false,
            instituteInfoObject: {
                pageLimit: 10,
                pageNo: 0,
                year: '',
                month: ''
            },
            first: 0,
            rows: 10,

            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.managementPointService = new ManagementPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.onPageChange = this.onPageChange.bind(this);

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    onPageChange(event) {
        let { instituteInfoObject } = this.state
        instituteInfoObject.pageNo = event.page

        this.setState({
            first: event.first,
            rows: event.rows
        });

        this.getMonthlyBillListForAllInstitute();
    }

    onChangeYearValue = (e) => {
        let { errors } = this.state;
        errors['year'] = '';
        this.setState({ yearValue: e.target.value, errors })
    }

    onChangeMonth = (e) => {
        let { errors } = this.state;
        errors['month'] = '';
        this.setState({ monthValue: e.target.value, errors })
    }

    onSubmitMonthlyBillStatement = () => {
        this.setState({ errorMsgVisible: false, errorMsgBody: '' });
        if (this.onSubmitFormError()) {
            this.setState({
                topProgressBar: true,
                secondDataTableIsLoading: true,
                dataTableIsLoading: true,
                errorMsgVisible: false,
                first: 0,
                page: 0
            });
            this.getMonthlyBillListForAllInstitute();
        }
    }

    onSubmitFormError = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.yearValue === '') {
            formIsValid = false;
            errors["year"] = "Year can't left empty.";
        }
        if (this.state.monthValue === '') {
            formIsValid = false;
            errors["month"] = "Month can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    getMonthlyBillListForAllInstitute = () => {

        this.setState({ allInstituteMonthlyBillList: [] });

        let instituteInfoObject = {
            year: this.state.yearValue,
            month: this.state.monthValue
        }

        this.setState({ topProgressBar: true, secondDataTableIsLoading: true, errorMsgVisible: false });
        this.managementPointService.getEdumanMonthlyBillReportForAllInstitute(instituteInfoObject)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log('body', body);

                        let firstTableObj = {
                            totalInstitute: body.totalInstitute.toLocaleString('EN-IN'),
                            totalBillableStudent: body.totalBillableStudent.toLocaleString('EN-IN'),
                            totalDisableStudent: body.totalDisableStudent.toLocaleString('EN-IN'),
                            averageRate: body.averageRate.toFixed(2),
                            totalBillAmount: this.ValidatorUtility.currencyFormatter(body.totalBillAmount),
                            totalDiscountAmount: this.ValidatorUtility.currencyFormatter(body.totalDiscountAmount),
                            totalPaidAmount: this.ValidatorUtility.currencyFormatter(body.totalPaidAmount),
                            totalDueAmount: this.ValidatorUtility.currencyFormatter(body.totalDueAmount),
                        };

                        body.instituteList.map(item => {
                            item.billableStudent = item.billableStudent.toLocaleString('EN-IN');
                            item.disableStudent = item.disableStudent.toLocaleString('EN-IN');
                        })

                        this.setState({ allInstituteMonthlyBillList: body.instituteList, monthlyStatementList: [firstTableObj] });
                        this.setState({ topProgressBar: false, secondDataTableIsLoading: false, dataTableIsLoading: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, secondDataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    BillAmountTemp = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData.geneateAmount)} </div>)
    }

    DiscountAmountTemp = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData.discountAmount)}</div>)
    }

    PaidAmountTemp = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData.paidAmount)}</div>)
    }

    DueAmountTemp = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData.dueAmount)}</div>)
    }

    totalBillAmountBody = (rowData) => {
        return (<div className="col-number text-right"> {rowData.totalBillAmount}</div>)
    }

    totalDiscountAmountBody = (rowData) => {
        return (<div className="col-number text-right"> {rowData.totalDiscountAmount}</div>)
    }

    totalBillPaidBody = (rowData) => {
        return (<div className="col-number text-right"> {rowData.totalPaidAmount}</div>)
    }

    totalBillDueBody = (rowData) => {
        return (<div className="col-number text-right"> {rowData.totalDueAmount}</div>)
    }

    totalAvarageRate = (rowData) => {
        return (<div className="col-number text-right"> {rowData.averageRate}</div>)
    }

    exportPdf() {

        let mainTitle = "EM Monthly Bill Summary & Details of " + this.state.monthValue + ' ' + this.state.yearValue
        let subTitleColumn = [
            { title: "Total Institute", dataKey: "totalInstitute" },
            { title: "Total Billable Student", dataKey: "totalBillableStudent" },
            { title: "Total Disable Student", dataKey: "totalDisableStudent" },
            { title: "Average Rate", dataKey: "averageRate" },
            { title: "Total Bill Amount", dataKey: "totalBillAmount" },
            { title: "Total Discount Amount", dataKey: "totalDiscountAmount" },
            { title: "Total Paid Amount", dataKey: "totalPaidAmount" },
            { title: "Total Due Amount", dataKey: "totalDueAmount" },
        ];

        let subTitleColStyle = {
            averageRate: { halign: "right" },
            totalBillAmount: { halign: "right" },
            totalDiscountAmount: { halign: "right" },
            totalPaidAmount: { halign: "right" },
            totalDueAmount: { halign: "right" },
        }

        let tableColumns = [
            { title: "Institute ID", dataKey: "instituteID" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Academic Year", dataKey: "academicYear" },
            { title: "Billable Student", dataKey: "billableStudent" },
            { title: "Disable Student", dataKey: "disableStudent" },
            { title: "Bill Amount", dataKey: "geneateAmount" },
            { title: "Discount Amount", dataKey: "discountAmount" },
            { title: "Paid Amount", dataKey: "paidAmount" },
            { title: "Due Amount", dataKey: "dueAmount" },
        ];

        let tableColStyle = {
            geneateAmount: { halign: "right" },
            discountAmount: { halign: "right" },
            paidAmount: { halign: "right" },
            dueAmount: { halign: "right" },
        }

        pdfDownloadLandscape_Two_TableWithSubTitle(mainTitle, 100, subTitleColumn, this.state.monthlyStatementList, subTitleColStyle, tableColumns, this.state.allInstituteMonthlyBillList, tableColStyle, "Monthly_Statement_of_EM_Bill.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        // excel download related
        let excelSheetName1 = this.state.monthValue + '' + this.state.yearValue + "_BillSummary";
        let excelSheetName2 = this.state.monthValue + '' + this.state.yearValue + "_BillInstitutes";

        let excelColumns_1stSheet = "";
        excelColumns_1stSheet = [
            <Workbook.Column value="totalInstitute" label="Total Institute" />,
            <Workbook.Column value="totalBillableStudent" label="Total Billable Student" />,
            <Workbook.Column value="totalDisableStudent" label="Total Disable Student" />,
            <Workbook.Column value="averageRate" label="Average Rate" />,
            <Workbook.Column value="totalBillAmount" label="Total Bill Amount" />,
            <Workbook.Column value="totalDiscountAmount" label="Total Discount Amount" />,
            <Workbook.Column value="totalPaidAmount" label="Total Paid Amount" />,
            <Workbook.Column value="totalDueAmount" label="Total Due Amount" />,
        ];

        let excelColumns_2ndSheet = "";
        excelColumns_2ndSheet = [
            <Workbook.Column value="instituteID" label="Institute ID" />,
            <Workbook.Column value="instituteName" label="Institute Name" />,
            <Workbook.Column value="academicYear" label="Academic Year" />,
            <Workbook.Column value="billableStudent" label="Billable Student" />,
            <Workbook.Column value="disableStudent" label="Disable Student" />,
            <Workbook.Column value="geneateAmount" label="Bill Amount" />,
            <Workbook.Column value="discountAmount" label="Discount Amount" />,
            <Workbook.Column value="paidAmount" label="Paid Amount" />,
            <Workbook.Column value="dueAmount" label="Due Amount" />,
        ];
        // excel download related end

        let { topProgressBar, errorMsgVisible, errorMsgBody, monthlyStatementList, allInstituteMonthlyBillList } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Statement Summary</div><div className="header-title-right"><a>Total Found: {monthlyStatementList.length}</a></div>
        </div>;

        let tableHeader2 = <div className="header-title">
            <div className="header-title-left">Institute List &  Statement Details</div><div className="header-title-right"><a>Total Found: {allInstituteMonthlyBillList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let currentFullDate = new Date();
        let currentYear = currentFullDate.getFullYear('yyyy');
        // let yearSelection = [
        //     { "label": "2019", "value": 2019 },
        //     { "label": "2018", "value": 2018 },
        //     { "label": "2017", "value": 2017 },
        //     { "label": "2016", "value": 2016 },
        //     { "label": "2015", "value": 2015 },
        //     { "label": "2014", "value": 2014 },
        //     { "label": "2013", "value": 2013 },
        // ]

        let yearSelection = [];

        let monthList = [
            { "label": "January", "value": "January" },
            { "label": "February", "value": "February" },
            { "label": "March", "value": "March" },
            { "label": "April", "value": "April" },
            { "label": "May", "value": "May" },
            { "label": "June", "value": "June" },
            { "label": "July", "value": "July" },
            { "label": "August", "value": "August" },
            { "label": "September", "value": "September" },
            { "label": "October", "value": "October" },
            { "label": "November", "value": "November" },
            { "label": "December", "value": "December" },
        ]

        ///year generator
        for (let index = 2013; index <= currentYear; index++) {
            yearSelection.unshift({ label: `${index}`, value: `${index}` });
        }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-5 p-lg-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Year <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Year"
                                            value={this.state.yearValue}
                                            options={yearSelection}
                                            name="year"
                                            autoWidth={false}
                                            onChange={this.onChangeYearValue}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["year"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-5 p-lg-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Month <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Month"
                                            value={this.state.monthValue}
                                            options={monthList}
                                            autoWidth={false}
                                            name="month"
                                            onChange={this.onChangeMonth}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["month"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-12 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitMonthlyBillStatement}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            header={tableHeader}
                                            responsive={true}
                                            value={monthlyStatementList}
                                        >
                                            <Column field="totalInstitute" header="Total Institute" />
                                            <Column field="totalBillableStudent" header="Total Billable Student" />
                                            <Column field="totalDisableStudent" header="Total Disable Student" />
                                            <Column field="averageRate" header="Average Rate" body={this.totalAvarageRate} />
                                            <Column field="totalBillAmount" header="Total Bill Amount " body={this.totalBillAmountBody} />
                                            <Column field="totalDiscountAmount" header="Total Discount Amount " body={this.totalDiscountAmountBody} />
                                            <Column field="totalPaidAmount" header="Total Paid Amount " body={this.totalBillPaidBody} />
                                            <Column field="totalDueAmount" header="Total Due Amount " body={this.totalBillDueBody} />
                                        </DataTable>
                                }
                            </div>
                            <br />
                            <div className="nw-data-table">
                                {
                                    this.state.secondDataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <div>
                                            <DataTable
                                                header={tableHeader2}
                                                responsive={true}
                                                value={allInstituteMonthlyBillList}
                                                responsive={true}
                                                paginator={true}
                                                rows={20}
                                                rowsPerPageOptions={[20, 100, 150, 200]}
                                            >
                                                <Column field="instituteID" header="Institute ID" filter={true} style={{ width: '120px' }} />
                                                <Column field="instituteName" header="Institute Name" filter={true} />
                                                <Column field="academicYear" header="Academic Year" filter={true} style={{ width: '75px' }} />
                                                <Column field="billableStudent" header="Billable Student" filter={true} style={{ width: '75px' }} />
                                                <Column field="disableStudent" header="Disable Student" filter={true} style={{ width: '75px' }} />
                                                <Column field="geneateAmount" header="Bill Amount" body={this.BillAmountTemp} filter={true} style={{ width: '100px' }} />
                                                <Column field="discountAmount" header="Discount Amount" body={this.DiscountAmountTemp} filter={true} style={{ width: '100px' }} />
                                                <Column field="paidAmount" header="Paid Amount" body={this.PaidAmountTemp} filter={true} style={{ width: '100px' }} />
                                                <Column field="dueAmount" header="Due Amount" body={this.DueAmountTemp} filter={true} style={{ width: '100px' }} />
                                            </DataTable>

                                            <div className="nw-button-parent m-t-20">

                                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                                <div style={{ display: 'none' }}>
                                                    <Workbook
                                                        filename="Monthly EM Bill Statement.xlsx"
                                                        element={
                                                            <Button
                                                                id="clickExcelBtn"
                                                                label="Download Excel"
                                                                icon="fas fa-download"
                                                                className="p-button p-button-primary nw-button nw-button-right"
                                                            />
                                                        }
                                                    >
                                                        <Workbook.Sheet data={monthlyStatementList} name={excelSheetName1}>
                                                            {excelColumns_1stSheet}
                                                        </Workbook.Sheet>
                                                        <Workbook.Sheet data={allInstituteMonthlyBillList} name={excelSheetName2}>
                                                            {excelColumns_2ndSheet}
                                                        </Workbook.Sheet>
                                                    </Workbook>
                                                </div>

                                            </div >

                                        </div>
                                }
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        )
    }
}