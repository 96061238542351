import React, { Component } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import App from "./App";
// import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import Login from './Login';
import AuthService from './auth/AuthService';
import Registration from "./components/registration/Registrationcomponent";
import ForgetPassword from "./components/guestAccess/ForgetPassword";
import { BijoyContentDownload } from './components/bijoyPoint/download/BijoyContentDownload';
import { GUEST_POINT, BIJOY_POINT } from './utils/PointWiseRouteConsts';
import { DeviceNewUser } from './components/bijoyPoint/deviceUnlock/DeviceNewUser';
import PaymentStatus from './components/dwsPoint/admisia/reports/PaymentStatus';
import PaymentCancel from './components/dwsPoint/admisia/reports/PaymentCancel';
import PaymentFailed from './components/dwsPoint/admisia/reports/PaymentFailed';


class AppWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		const Auth = new AuthService();
		const isLog = Auth.getToken();
		
		switch (this.props.location.pathname) {
			case "/PaymentFailed":
				return <Route path={GUEST_POINT.PaymentFailed} component={PaymentFailed} />

			case "/PaymentCancel":
				return <Route path={GUEST_POINT.PaymentCancel} component={PaymentCancel} />

			case "/PaymentStatus":
				return <Route path={GUEST_POINT.PaymentStatus} component={PaymentStatus} />
			case "/login":
				return <Route path={GUEST_POINT.LOGIN} component={Login} />
			case "/signup":
				return <Route path={GUEST_POINT.SIGN_UP} component={Registration} />
			case "/error":
				return <Route path="/error" component={Error} />
			case "/notfound":
				return <Route path="/notfound" component={NotFound} />
			case "/access":
				return <Route path="/access" component={Access} />
			case "/forget_password":
				return <Route path={GUEST_POINT.FORGET_PASSWORD} component={ForgetPassword} />
			case "/explore_bijoy_content":
				return <Route path={BIJOY_POINT.EXPLORE_BIJOY_CONTENT} component={BijoyContentDownload} />
				case "/device_unlock":
					return <Route path={BIJOY_POINT.GUEST_USER_DEVICE_UNLOCK} component={DeviceNewUser} />
			default:
				// Updated at 23-10-2019 by ABsiddik
				switch (isLog) {
					case null:
						return <div>
							<Route
								render={props => (
									<Redirect to={{
										pathname: GUEST_POINT.LOGIN
									}} />

								)}
							/>
						</div>
					default:
						return <App props={this.props}/>;
				}
				// return <App props={this.props} />;
		}
		// switch (isLog) {
		// 	case false:
		// 		return <div>
		// 			<Route path="/login" component={Login} />
		// 			<Route
		// 				render={props => (
		// 					<Redirect to={{
		// 						pathname: '/login'
		// 					}} />

		// 				)}
		// 			/>
		// 		</div>
		// 	default:
		// 		return <App props={this.props}/>;
		// }

	}
}

export default withRouter(AppWrapper);