import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import CommonFuctionality from '../../common/CommonFuctionality';
import { InputSwitch } from 'primereact/inputswitch';

let urlId;
export class PhotoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            uploadImage: {},
            yearDropDownList: [],
            homePagePhotoList: [],
            galleryPhotoList: [],
            photoSubmitError: [],
            photoInformation: {
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            checkEligibility: true,
            errorMsgVisible: false,
            errorMsgBody: '',
            dialogDropDownIsLoading: false
        }

        this.NetiContentLoader = new NetiContentLoader();
        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.propsLoad();
        this.CommonFuctionality = new CommonFuctionality();

        this.getImg = this.getImg.bind(this);
        this.getImg();
    }


    propsLoad = () => {
        let { photoInformation } = this.state;
        console.log("photo gallery", this.props.rowInformation);
        photoInformation.photoGalleryId = this.props.rowInformation.photoGalleryId;
        photoInformation.photoType = this.props.rowInformation.photoType;
        photoInformation.photoTitle = this.props.rowInformation.photoTitle;
        photoInformation.photoDetails = this.props.rowInformation.photoDetails;
        photoInformation.photoName = this.props.rowInformation.photoName;
        photoInformation.photoPath = this.props.rowInformation.photoPath;
        photoInformation.photoSerial = this.props.rowInformation.photoSerial;
        photoInformation.photoStatus = this.props.rowInformation.photoStatus;
        photoInformation.photoCreateDate = this.props.rowInformation.photoCreateDate;
        photoInformation.photoCreateYear = this.props.rowInformation.photoCreateYear;
        photoInformation.photoContent = this.props.rowInformation.photoContent;
        photoInformation.photoSaveOrEditable = this.props.rowInformation.photoSaveOrEditable;
        this.setState({ photoInformation });
        this.reloadCoreUrlIdFromLocal();
    }


    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        this.getClassOrGroupListByDefaultCode('T107');
        urlId = this.DwsService.getUrlInfoFromLocalStorage();

        if (urlId) {
            this.setState({ homeReturnButton: false });
            let { photoInformation } = this.state;
            photoInformation.coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ photoInformation });
        }
        else {
            this.setState({ homeReturnButton: true })
        }

    }

    getClassOrGroupListByDefaultCode(defaultCode) {

        this.setState({ dialogDropDownIsLoading: true });
        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if (defaultCode === 'T107') {
                            this.setState({ yearDropDownList: body, dialogDropDownIsLoading: false });
                        }
                    });
                } else if (res.status == 404) {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ dialogDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {

                this.setState({ dialogDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });

            });
    }


    serialNumberHandler = (event, props) => {
        let { photoInformation } = this.state;
        photoInformation.photoSerial = event.target.value;
        this.setState({ photoInformation });

    }

    photoTypeHandler = (event, props) => {
        let { photoInformation } = this.state;
        photoInformation.photoType = event.target.value;
        //this.setState({ photoInformation });
    }

    titleHandler = (event, props) => {
        let { photoInformation } = this.state;
        photoInformation.photoTitle = event.target.value;
        this.setState({ photoInformation });
    }

    photoDetailsHandeler = (event, props) => {
        let { photoInformation } = this.state;
        photoInformation.photoDetails = event.target.value;
        this.setState({ photoInformation });
    }

    photoYearHandler = (event, props) => {
        let { photoInformation } = this.state;
        photoInformation.photoCreateYear = event.target.value;
        this.setState({ photoInformation });
    }

    photoStatushandler = (event, props) => {
        let { photoInformation } = this.state;

        if(event.target.value){
            photoInformation.photoStatus = 1
        }
        else{
            photoInformation.photoStatus = 0
        }
        // photoInformation.photoStatus = event.target.value;
        this.setState({ photoInformation });
    }

    onLoadPic(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ uploadImage: album });

        }
    }

    updatePhotoHandler = (e) => {
        let { photoInformation } = this.state;


        if (this.state.uploadImage.contentPic != null) {
            photoInformation.photoContent = this.state.uploadImage.contentPic;
            photoInformation.photoSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);

            photoInformation.photoName = this.state.uploadImage.contentName;
            
        }


        if (this.errorHandler()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.DwsService.updatePhoto(photoInformation)
                .then(res => {
                    if (res.status == 202) {
                        this.props.diagGrowl('success', 'Updated successfully')
                        this.props.turnOffDialog();
                        this.setState({ topProgressBar: false, errorMsgVisible: false });

                        this.CommonFuctionality.blurDialogBackgroundDeActive()
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
                });
        }
    }

    errorHandler() {
        let { photoInformation, photoSubmitError } = this.state;
        let formIsValid = true;
        if (photoInformation.photoSerial === '') {
            formIsValid = false;
            photoSubmitError["photoSerial"] = "Photo serial can't left empty";
        }
        if (photoInformation.photoType === '') {
            formIsValid = false;
            photoSubmitError["photoType"] = "Photo Type can't left empty";
        }
        if (photoInformation.photoTitle === '') {
            formIsValid = false;
            photoSubmitError["photoTitle"] = "Photo Title can't left empty.";
        }
        if (photoInformation.photoDetails === '') {
            formIsValid = false;
            photoSubmitError["photoDetails"] = "Photo Details can't left empty.";
        }
        if (photoInformation.photoCreateYear === '') {
            formIsValid = false;
            photoSubmitError["photoCreateYear"] = "Year can't left empty.";
        }
        if (photoInformation.photoContent === '') {
            formIsValid = false;
            photoSubmitError["photoContent"] = "Image can't left empty.";
        }
        this.setState({ photoSubmitError });
        return formIsValid;
    }


    photoStatusCheck = (rowData) => {
        let switchButtonEnable = "switch-button-yes", switchButtonYes, switchButtonNo, toggleOnOff;
        let toggleOnOffValue;
        if (rowData.photoStatus === 1) {
            switchButtonYes = "switch-button " + switchButtonEnable
            toggleOnOff = "fas fa-toggle-on switch-button-yes"
            toggleOnOffValue = true
        } else if (rowData.photoStatus === 0) {
            switchButtonNo = "switch-button " + switchButtonEnable
            toggleOnOff = "fas fa-toggle-off switch-button-no"
            toggleOnOffValue = false
        }
        return (
            <div className="input-switch-button">
                <span className={switchButtonNo}>No </span> 
                {/* <i class={toggleOnOff} /> */}

                <InputSwitch
                    onChange={(e) => this.photoStatushandler(e, this.props)}
                    checked={toggleOnOffValue}
                />

                <span className={switchButtonYes}> Yes</span>
            </div>
        )
    }

    photoPublishTo = (rowData) => {
        let activeHomePage = "far fa-circle", activeGallery = "far fa-circle";
        let homePublishStatus = false;
        let galleryPublishStatus = false;

        if (rowData.photoType === "Home Page") {
            activeHomePage = "fas fa-check-circle"
            homePublishStatus = true

        } else if (rowData.photoType === "gallery") {
            activeGallery = "fas fa-check-circle"
            galleryPublishStatus = true
        }
        return (
            <div className="input-radio-button">
                <div className="radio-button-inside">
                    {/* <i class={activeHomePage}></i> */}
                    <RadioButton
                        value="Home Page"
                        onChange={(e) => this.photoTypeHandler(e, this.props)}
                        checked={homePublishStatus}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Home Page</label>
                </div>

                <div className="radio-button-inside">
                    {/* <i class={activeGallery}></i> */}
                    <RadioButton
                        value="gallery"
                        onChange={(e) => this.photoTypeHandler(e, this.props)}
                        checked={galleryPublishStatus}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Gallery</label>
                </div>

                {/* { rowData.photoType} */}
            </div>
        )
    }

    getImg() {
        let { photoInformation } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        let photoMainPath;

        if (this.state.photoName) {
            photoMainPath = "/images/nw/dws/gallery/" + this.state.photoName
        }
        else {
            photoMainPath = photoInformation.photoPath
        }

        if (photoMainPath !== null) {
            this.netiFileHandler.getByteImage(photoMainPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {

                                let contentType = this.netiFileHandler.getImageContentType(photoInformation.photoName);
                                this.setState({ viewUpdatePicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ viewUpdatePicture: staticImg });
                    }
                });
        } else {
            this.setState({ viewUpdatePicture: staticImg });
        }

    }

    removeButtonOnclick = () =>{
            this.setState({ 
                uploadImage: { 
                    contentPic: '' 
                }, 
                viewUpdatePicture: '' 
            })
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let yearList = [];
        if ((this.state.yearDropDownList != null) && (this.state.yearDropDownList.length > 0)) {
            yearList = this.state.yearDropDownList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }

        const photoTypeField = [
            { label: 'Home Page', value: 'Home Page' },
            { label: 'Gallery', value: 'gallery' }
        ];

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(370 x 240 px (recommended)</span></div>


        // console.log("STATE::::", this.state);
        

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                
                <Growl ref={(el) => this.growl = el} />
                
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Serial No. <span>*</span></span>
                                            <div className="nw-inputgroup-desc">{this.state.photoInformation.photoSerial}</div>
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError.photoSerial}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">TITLE OF THE IMAGE <span>*</span></span>
                                            <InputText
                                                id="photoTitle"
                                                value={this.state.photoInformation.photoTitle}
                                                placeholder="Enter your title"
                                                onChange={(e) => this.titleHandler(e, this.props)}
                                                placeholder="Enter photo title"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError.photoTitle}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">DESCRIPTION <span>*</span></span>
                                            <InputTextarea
                                                className=""
                                                id="details"
                                                onChange={(e) => this.photoDetailsHandeler(e, this.props)}
                                                value={this.state.photoInformation.photoDetails}
                                                name="details"
                                                rows={5}
                                                cols={30}
                                                placeholder="Write photo details"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError.photoDetails}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon addon-no-style">PUBLISH THIS POST <span></span></span>
                                            
                                            <div className="nw-inputgroup-desc desc-no-style">{this.photoStatusCheck(this.state.photoInformation)}</div>

                                        </div>
                                        <span className="error-message"></span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon addon-no-style">PUBLISH TO <span></span></span>
                                            
                                            <div className="nw-inputgroup-desc desc-no-style">{this.photoPublishTo(this.state.photoInformation)}</div>

                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError.photoType}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">PHOTO YEAR <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Photo Year"
                                                value={this.state.photoInformation.photoCreateYear}
                                                options={yearList}
                                                id="yearList"
                                                autoWidth={false}
                                                onChange={(e) => this.photoYearHandler(e, this.props)}
                                            />
                                            </div>
                                        <span className="error-message">{this.state.photoSubmitError.photoCreateYear}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Image <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/JPEG/GIF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.uploadImage.contentPic || this.state.viewUpdatePicture ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={this.removeButtonOnclick}
                                                                />
                                                                <img
                                                                    src={
                                                                        this.state.viewUpdatePicture === '' ?
                                                                            "data:" + this.state.uploadImage.extention + ";base64," + this.state.uploadImage.contentPic
                                                                            :
                                                                            this.state.viewUpdatePicture
                                                                    }
                                                                    
                                                                    style={{ "height": "80px" }}
                                                                />


                                                            </div>
                                                            <div className="image-title">{this.state.uploadImage.contentName }</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}
                                                            mode="basic"
                                                            accept="image/*"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onLoadPic.bind(this)}
                                                            auto={true}
                                                        />
                                                }

                                            </div>
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError["photoContent"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={e => this.props.turnOffDialog()}
                                        />

                                        <Button
                                            name="district"
                                            label="Update"
                                            icon="fas fa-check"
                                            className="p-button-primary nw-button nw-button-multiple"
                                            onClick={(e) => this.updatePhotoHandler(e)}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}