import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {Growl} from 'primereact/growl';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { Message } from 'primereact/message';
import { FileUpload } from 'primereact/fileupload';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import Additionalinformation from './UpdateAdditionalInfo';
import { ProfileService } from '../../../service/profile/ProfileService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { RegistrationService } from '../../../service/registration/RegistrationService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import CommonFuctionality from '../../common/CommonFuctionality';

export class UpdateProfileForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            password:this.props.userPassword,
            visible: false,
            date: '',
            wrongEmail: true,
            religionSet:'',
            districtList: '',
            districtId: '',
            areaList: '',
            areaSet: '',
            areaID:'',
            topProgressBar:false,
            errorMsgVisible: false,
            errorMsgBody: '',
            dataTableIsLoading:false,
            secondDataTableIsLoading:false,
            religionVisible: false,
            userbasicinfo: {
                globalAreaInfoDTO:{
                    categoryName:'',
                    coreCategoryID:''
                }
            },
            proPic: {
                contentName: ''
            },
        }
        this.fullNameHandaler = this.fullNameHandaler.bind(this)
        this.mobileNUmberHandler = this.mobileNUmberHandler.bind(this)
        this.emailHandler = this.emailHandler.bind(this)
        this.onClick = this.onClick.bind(this);
        this.genderHandle = this.genderHandle.bind(this)
        this.religionHandle = this.religionHandle.bind(this)
        this.dateOfBirthHandler = this.dateOfBirthHandler.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.onChangeDistrict = this.onChangeDistrict.bind(this)
        this.profileService = new ProfileService();
        this.netiFileHandler = new NetiFileHandler();
        this.registrationService = new RegistrationService();
        this.ErrorMessageView = new ErrorMessageView();
        this.NetiContentLoader = new NetiContentLoader();
        this.getImg = this.getImg.bind(this);
        this.CommonFuctionality = new CommonFuctionality();

    }

    componentWillMount() {
        this.getUserProfileList();
        this.getDistrictList();
       
    }

    getUserProfileList () {
        this.setState({ topProgressBar: true, dataTableIsLoading:true, errorMsgVisible:false});
        this.profileService.getUserProfileInfo()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ userbasicinfo: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading:false, errorMsgVisible:false});
                        this.getImg();
                    });
                } else {
                    this.profileService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
                }
        }).catch( err =>{
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
        })

    }

    getDistrictList () {
        this.setState({ topProgressBar: true, secondDataTableIsLoading:true, errorMsgVisible:false});
        this.registrationService.fetchDistrictList()
        .then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    this.setState({ districtList: body });
                    this.setState({ topProgressBar: false, secondDataTableIsLoading:false, errorMsgVisible:false});

                });
            } else {
                this.registrationService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, secondDataTableIsLoading:false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
                
            }
        }).catch( err =>{
            this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
        })

    }



    fullNameHandaler = (event, props) => {
        let { userbasicinfo } = this.state;
        userbasicinfo[event.target.name] = event.target.value;
        this.setState({ userbasicinfo });
    }

    mobileNUmberHandler = (event, props) => {
        let {userbasicinfo } = this.state;
        userbasicinfo.basicMobile = event.target.value;
        this.setState({ userbasicinfo });
    }

    emailHandler = (event, props) => {
        let { userbasicinfo } = this.state;
        userbasicinfo[event.target.name] = event.target.value;
        this.setState({ userbasicinfo });
    }

    genderHandle = (event, props) => {
        let { userbasicinfo } = this.state;
        userbasicinfo[event.target.name] = event.target.value;
        this.setState({ userbasicinfo });
    }

    religionHandle = (event, props) => {
        let { userbasicinfo } = this.state;
        userbasicinfo[event.target.name] = event.target.value;
        this.setState({ userbasicinfo });
    }

    dateOfBirthHandler = (event, props) => {
        let { userbasicinfo } = this.state;
        userbasicinfo[event.target.name] = NetiDateUtils.getDateFromString(event.target.value);
        this.setState({ userbasicinfo });
    }

    // (e) {
    onLoadPic(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });

        }
        // this.growl.show({severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode'});
    }

    onClick(event) {
        this.setState({ visible: true });
    }

    onChangeDistrict(e) {
        this.setState({districtId:e.target.value})
        this.setState({ topProgressBar: true, secondDataTableIsLoading:true, errorMsgVisible:false});
        this.registrationService.fetchAreaList(e.value)
            .then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    this.setState({ areaList: body });
                    this.setState({ topProgressBar: false, secondDataTableIsLoading:false, errorMsgVisible:false});
                });
            } else {
                this.registrationService.Auth.handleErrorStatus(res)
                .then((responseBody) => {
                    this.setState({ topProgressBar: false, secondDataTableIsLoading:false, errorMsgVisible: true, errorMsgBody: responseBody })
                });
            }
        }).catch( err =>{
            this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
        })
    }

    areaIDHandle = (event, props) => {
        this.setState({areaID:event.target.value});
        let {userbasicinfo} = this.state;
        this.state.userbasicinfo.globalAreaInfoDTO.coreCategoryID = event.target.value;
        this.setState({userbasicinfo});
    }

    areaHandle = (event, props) => {
       let areaID = this.state.areaID;
       let areaOptions = [];
        if (this.state.areaList && this.state.areaList.length) {
            areaOptions = this.state.areaList.map((item) => ({
              label: item.categoryName,  
              value: item.coreCategoryID,
            }));
        }
        let areaName = '';
        for (let i = 0; i < areaOptions.length; i++) {
            if (areaOptions[i].value == areaID) {
                areaName = areaOptions[i].label;
            }
        }
        let {userbasicinfo} = this.state;
        this.state.userbasicinfo.globalAreaInfoDTO.categoryName = areaName;
        this.setState({userbasicinfo});
        this.setState({visible:false});

        this.onHide();
       
    }

    areaSet = () =>{}

    getImg() {
        let { userbasicinfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        if (userbasicinfo.imagePath !== null) {
            this.netiFileHandler.getByteImage(userbasicinfo.imagePath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                console.log("BODY", body);

                                let contentType = this.netiFileHandler.getImageContentType(userbasicinfo.imageName);
                                this.setState({ viewUpdatePicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ viewUpdatePicture: staticImg });
                    }
                });
        } else {
            this.setState({ viewUpdatePicture: staticImg });
        }

    }

    removeButtonOnclick = () =>{
        this.setState({ proPic: { contentPic: '' }, viewUpdatePicture: '' })
    }

    updateProfile() {
        
        let { userbasicinfo } = this.state;
        
        userbasicinfo.userPassword = this.state.password;
        this.setState({ userbasicinfo });
        const email = userbasicinfo.basicEmail
        if (/^[a-z/_/A-Z0-9/./-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
            this.setState({ wrongEmail: true })
        } else {
            this.setState({ wrongEmail: false })
        }
       
        if (this.state.proPic.contentPic != null) {
            userbasicinfo.imageContent = this.state.proPic.contentPic;
            userbasicinfo.imageSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            userbasicinfo.imageName = 'pro_pic_' + userbasicinfo.netiID + extention;
        }
        
        this.setState({ topProgressBar: true, dataTableIsLoading:true, errorMsgVisible:false});
        console.log("User Basic Info obj",userbasicinfo);

        this.profileService.updateUserbasicInfo(userbasicinfo)
        .then((res)=>{
            if(res.status==200){
                console.log("Image Upload",res);
                
                this.growl.show({severity: 'success', summary: 'Success Message', detail: 'Successfully Updated'});
                this.setState({ topProgressBar: false, dataTableIsLoading:false, errorMsgVisible:false});
            }else{
                this.profileService.Auth.handleErrorStatus(res)
                .then((responseBody) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading:false, errorMsgVisible: true, errorMsgBody: responseBody })
                });
                
            }
        }).catch( err =>{
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
        })
    }

    religionDialogView = () =>{
        this.setState({ religionVisible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    areaDialogView = () =>{
        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHide = () =>{
        this.setState({ religionVisible: false, visible:false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">370 x 240 px (recommended)</span></div>
        let {userbasicinfo} = this.state.userbasicinfo
        
        const religion = [
            { label: 'Select Religion', value: null },
            { label: 'Muslim', value: 'Muslim' },
            { label: 'Hindu', value: 'Hindu' },
            { label: 'Christian', value: 'Christian' },
            { label: 'Budhist', value: 'Budhist' }
        ]
        let districtOptions = []
        if (this.state.districtList && this.state.districtList.length) {
            districtOptions = this.state.districtList.map((item) => ({
              label: item.categoryName,
              value: item.coreCategoryID,
            }));
        }

        let areaOptions = []
        if (this.state.areaList && this.state.areaList.length) {
            areaOptions = this.state.areaList.map((item) => ({
              label: item.categoryName,  
              value: item.coreCategoryID,
            }));
        }
        return (
            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    <div className="p-grid nw-form blur-section">
                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                            <div className="nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    {this.state.wrongEmail === false ? <Message severity="error" text="Your Email Formet is Not Right" /> : ''}
                                </div>
                                
                                <div className="p-col-12 p-xl-12">
                                    {  this.state.dataTableIsLoading?
                                        this.NetiContentLoader.normalFormInputField():
                                    <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Full name</span>
                                            <InputText
                                                value={this.state.userbasicinfo.fullName}
                                                id="fullName"
                                                name="fullName"
                                                onChange={(event) => this.fullNameHandaler(event, this.props)}
                                                keyfilter={/^[a-zA-Z&\s]/}
                                                className="custom-field-control"
                                            />
                                        </div>
                                    }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <div className="input-radio-button">
                                            <span className="p-inputgroup-addon addon-no-style">Gender </span>
                                            <div className="radio-button-inside">
                                                <RadioButton
                                                    value="Male"
                                                    inputId="male"
                                                    name="gender"
                                                    onChange={(event) => this.genderHandle(event, this.props)}
                                                    checked={this.state.userbasicinfo.gender === "Male"}
                                                />
                                                <label htmlFor="male" className="p-radiobutton-label"><b>Male</b></label>
                                            </div>
                                            <div className="radio-button-inside">
                                                 <RadioButton
                                                    value="Female"
                                                    inputId="female"
                                                    name="gender"
                                                    onChange={(event) => this.genderHandle(event, this.props)}
                                                    checked={this.state.userbasicinfo.gender === "Female"}
                                                />
                                                <label htmlFor="female" className="p-radiobutton-label"><b>Female</b></label>
                                            </div>
                                            <div className="radio-button-inside">
                                                <RadioButton
                                                    value="Others"
                                                    inputId="Others"
                                                    name="gender"
                                                    onChange={(event) => this.genderHandle(event, this.props)}
                                                    checked={this.state.userbasicinfo.gender === "Others"}
                                                />
                                                <label htmlFor="others" className="p-radiobutton-label"><b>Others</b></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Religion</span>
                                            <InputText type="text"
                                            value={this.state.userbasicinfo.religion}
                                            id="religion"
                                            name="religion"
                                            className="custom-field-control"
                                        />
                                        <Button 
                                            icon="fas fa-pencil-alt" 
                                            onClick={ this.religionDialogView} 
                                        />
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Date of birth</span>
                                    <Calendar
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        showIcon={true}
                                        onChange={event => this.dateOfBirthHandler(event, this.props)}
                                        placeholder={this.state.userbasicinfo.dateOfBirth}
                                        value={this.state.userbasicinfo.dateOfBirth}
                                        className="custom-calender-input"
                                    />
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup nw-upload-button">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Select profile picture <span>*</span>
                                            <br />
                                            <span>(PNG/JPG/JPEG/GIF)</span>
                                        </span>
                                            <div className="nw-upload-button-inside">
                                            {
                                                this.state.proPic.contentPic || this.state.viewUpdatePicture ?
                                                <div className="image-view-main">
                                                    <div className="upload-image-view">
                                                        <Button
                                                            className="delete-upload-button"
                                                            icon="fas fa-times-circle"
                                                            onClick={this.removeButtonOnclick}
                                                        />
                                                        <img
                                                            src={
                                                                this.state.viewUpdatePicture === '' ?
                                                                    "data:" + this.state.proPic.extention + ";base64," + this.state.proPic.contentPic
                                                                    :
                                                                    this.state.viewUpdatePicture
                                                            }
                                                            style={{ "height": "80px" }}
                                                        />


                                                    </div>
                                                    <div className="image-title">{this.state.proPic.contentName || this.state.userbasicinfo.photoName}</div>
                                                </div>
                                                :
                                                <FileUpload
                                                    chooseLabel={this.state.proPic.contentName || uploadFileLabel}
                                                    mode="basic" 
                                                    accept="image/*"
                                                    maxFileSize={1000000} 
                                                    onSelect={this.onLoadPic.bind(this)} 
                                                    auto={true}
                                            />
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="p-col-12 p-xl-12">
                                {  this.state.dataTableIsLoading?
                                    this.NetiContentLoader.normalFormInputField():
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Mobile number</span>
                                        <InputText
                                            type="text"
                                            id="mobileNumber"
                                            name="basicMobile"
                                            value={this.state.userbasicinfo.basicMobile}
                                            onChange={(event) => this.mobileNUmberHandler(event, this.props)}
                                            keyfilter="pint"
                                            maxlength={11}
                                            className="custom-field-control"
                                        />
                                    </div>
                                }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                {  this.state.dataTableIsLoading?
                                    this.NetiContentLoader.normalFormInputField():
                                    <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Email address</span>
                                    <InputText
                                            type="email"
                                            name="basicEmail"
                                            id="emailAddress"
                                            value={this.state.userbasicinfo.basicEmail}
                                            onChange={(event) => this.emailHandler(event, this.props)}
                                            className="custom-field-control"
                                        />
                                    </div>
                                }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                {  this.state.dataTableIsLoading?
                                    this.NetiContentLoader.normalFormInputField():
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Area</span>
                                        <InputText type="text"
                                            id="area"
                                            value={this.state.userbasicinfo.globalAreaInfoDTO && this.state.userbasicinfo.globalAreaInfoDTO.categoryName}
                                            placeholder=""
                                            className="custom-field-control"
                                            onChange={this.areaSet.bind(this)}
                                        />
                                        <Button 
                                            icon="fas fa-pencil-alt" 
                                            onClick={ this.areaDialogView} 
                                        />
                                    </div>
                                }
                                </div>  

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button
                                        icon="fas fa-check"
                                        onClick={this.updateProfile}
                                        label="Update"
                                        className="p-button p-button-primary nw-button nw-button-right" 
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                        
                    <div className="p-col-12 p-col-nogutter">
                        <Additionalinformation />
                    </div>
                    
                </div>

                <div className="dialog-section">
                    <Dialog 
                        className="nw-dialog"
                        header="Select your religion"
                        visible={this.state.religionVisible}
                        modal={true}
                        onHide={ this.onHide }
                        style={{maxWidth: '450px', maxHeight: "250px"}}
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 nw-button-parent">
                                        <Dropdown
                                            placeholder="Select Religion"
                                            name="religion"
                                            options={religion}
                                            onChange={event => this.religionHandle(event, this.props)}
                                            value={this.state.userbasicinfo.religion}
                                            autoWidth={false}
                                            className="custom-field-control"
                                        />
                                    </div>
                                    
                                    <div className="p-col-12 nw-button-parent">
                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            icon="fas fa-check"
                                            name="district"
                                            label="Save"
                                            onClick={ this.onHide } />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog 
                        className="nw-dialog" 
                        header="Select your district and area"  
                        visible={this.state.visible} 
                        modal={true} 
                        onHide={ this.onHide }
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                {  this.state.secondDataTableIsLoading?
                                    this.NetiContentLoader.normalFormInputField():
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Select District</span>
                                            <Dropdown
                                                placeholder="Select your district"
                                                value={this.state.districtId}
                                                onChange={(e)=> this.onChangeDistrict(e)}
                                                options={districtOptions}
                                                autoWidth={false}
                                                className="custom-field-control"
                                            />
                                        </div>
                                    </div>
                                }

                                { this.state.secondDataTableIsLoading?
                                    this.NetiContentLoader.normalFormInputField():
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Select Area</span>
                                            <Dropdown
                                                placeholder="Select your area"
                                                value={this.state.areaID}
                                                options={areaOptions}
                                                autoWidth={false}
                                                className="custom-field-control"
                                                onChange={event => this.areaIDHandle(event, this.props)}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button
                                        name="district"
                                        className="p-button-primary nw-button nw-button-right"
                                        icon="fas fa-check"
                                        label="Done"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        onClick={this.areaHandle.bind(this)}
                                    />
                                </div>

                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>
            
                {/* <div className="p-grid nw-form">
                    <Additionalinformation />
                </div> */}
            </div>
        )
    }
}
