import React, { Component } from 'react';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiFileHandler } from '../../../utils//NetiFileHandler';

import { Growl } from 'primereact/growl';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';

let dialogType = '';
let dialogHeader = '';
let dropDownListName1 = '';
let cmsId = '';

export class AdminSyllabusInfo extends Component {

    constructor(props) {

        super(props);

        this.state = {
            homeReturnButton: false,
            dataTableIsLoading: false,
            dataTableValue: [],
            dialogVisible: false,
            classDropDownList: [],
            groupDropDownList: [],
            syllabusInfoObj: {
                syllabusId: '',
                classId: '',
                cmsId: 0,
                fileContent: '',
                fileName: "",
                fileSaveOrEditable: false,
                groupId: '',
                syllabusFileName: "",
                syllabusSerial: 0
            },
            selectedRowData: '',
            uploadImage: {

            },
            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            imageInputFieldIsLoading: false,
            errors: {},

        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.netiFileHandler = new NetiFileHandler();

        this.onLoadPic = this.onLoadPic.bind(this);
        this.getTableData = this.getTableData.bind(this);
        this.getClassOrGroupListByDefaultCode = this.getClassOrGroupListByDefaultCode.bind(this);
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {
        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .-->", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });

            this.getTableData();
            this.getClassOrGroupListByDefaultCode('class', cmsId);
            this.getClassOrGroupListByDefaultCode('group', cmsId);

        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    syllabusFormHandleError = () => {

        let { syllabusInfoObj, errors } = this.state;
        let formIsValid = true;

        if (dialogType === 'update') {

            if (syllabusInfoObj.syllabusSerial === '' || syllabusInfoObj.syllabusSerial === null) {
                formIsValid = false;
                errors["syllabusSerial"] = "Serial No. can't left empty.";
            }
        }

        if (!syllabusInfoObj.classId) {
            formIsValid = false;
            errors["classId"] = "Class can't left empty.";
        }
        if (!syllabusInfoObj.groupId) {
            formIsValid = false;
            errors["groupId"] = "Group can't left empty.";
        }
        if (this.state.uploadFile.fileName === '') {
            formIsValid = false;
            errors["image"] = "Upload file can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    getTableData() {

        this.setState({ firstDropDownIsLoading: true, secondDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.DwsService.getCmsSyllabusInfo(cmsId)
            .then((res) => {

                if (res.status == 200) {
                    return res.json().then((body) => {

                        // console.log('table-list', body.item);

                        this.setState({ dataTableValue: body.item });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    getClassOrGroupListByDefaultCode(type, cmsId) {
        let { errors } = this.state;
        this.setState({ firstDropDownIsLoading: true, secondDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.DwsService.cmsClassOrGroupList(type, cmsId)
            .then(res => {

                if (res.status == 200) {
                    return res.json().then((body) => {

                        if (type === 'class') {
                            this.setState({ classDropDownList: body.item });
                        }
                        else if (type === 'group') {
                            this.setState({ groupDropDownList: body.item });
                        }
                        errors['classError'] = ''
                        errors['groupError'] = ''
                        errors[dropDownListName1] = ''
                        this.setState({ errors: errors })
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });

                }
                else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            errors[dropDownListName1] = resp.message
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch(error => {
                errors['classError'] = 'Class List Not Found'
                errors['groupError'] = 'Group List Not Found'
                errors[dropDownListName1] = 'Connection Problem'
                this.setState({ errors: errors })
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

            });
    }

    onChangeToDialogVisible = (source) => {
        if (source == "insert") {
            dialogHeader = "Add Syllabus Info";
            dialogType = source;

            let { uploadFile, errors } = this.state
            uploadFile.fileName = ""
            uploadFile.fileContent = ""
            uploadFile.fileSaveOrEditable = false

            errors = {}

            this.setState({ uploadFile, errors })
        }
        else if (source == "update") {
            let { errors } = this.state
            errors = {}
            dialogHeader = "Update Syllabus Info";
            dialogType = source;
            this.setState({ errors })
        }
        this.setState({ dialogVisible: true });
    };

    onHideDialog = () => { this.setState({ dialogVisible: false }); this.resetDialogValue(); }

    showDeleteDialog = (dialogType, rowData) => {
        this.setState({ deleteDialogVisible: true, selectedRowData: rowData });
    }

    onHideDeleteDialog = () => {
        this.setState({ deleteDialogVisible: false });
    }

    resetDialogValue = () => {

        let syllabusInfoObj = {
            "syllabusId": 0,
            "classId": 0,
            "cmsId": 0,
            "groupId": 0,
            "fileContent": '',
            "fileName": "",
            "fileSaveOrEditable": false,
            "syllabusFileName": "string",
            "syllabusSerial": '',
        }
        this.clearErrorMsg("syllabusSerial")
        this.setState({ syllabusInfoObj: syllabusInfoObj });

    }
    onChageDropDownValue = (e, value, action) => {

        // console.log('e', e);

        let { syllabusInfoObj } = this.state;

        if (dialogType == 'insert') {
            syllabusInfoObj[e.target.name] = value;
        }
        else if (dialogType == 'update') {
            syllabusInfoObj[e.target.name] = value
        }

        this.setState({ syllabusInfoObj })
        this.clearErrorMsg(e.target.name);
    }

    removeUploadFile = () => {

        let { uploadFile, syllabusInfoObj } = this.state;

        uploadFile.fileContent = ''
        uploadFile.fileName = ''
        uploadFile.fileSaveOrEditable = false

        this.setState({ uploadFile, syllabusInfoObj });
        Object.assign(syllabusInfoObj, uploadFile);
    }

    onLoadPic = (e, maxFileSize) => {

        let { errors } = this.state;

        let fileType = this.netiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/doc', 'application/docx'];

        if (supportedExtention.includes(fileType)) {

            if (this.netiFileHandler.getMaxFileSizeIsValid(e.files[0].size, maxFileSize)) {

                errors["image"] = "";
                this.setState({ errors });

                var reader = new FileReader();
                let photo = e.files[0];

                const scope = this
                reader.readAsDataURL(photo);
                reader.onload = () => {
                    let content = reader.result;
                    var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                    var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                    let album = {
                        fileContent: urlStr,
                        fileName: photo.name,
                        fileSaveOrEditable: true
                    };
                    scope.setState({ uploadFile: album });
                    Object.assign(this.state.syllabusInfoObj, album);

                }

            }
            // else {

            //     errors["image"] = "File size can't be more than " + maxFileSize / 1048576 + ' MB';
            //     this.setState({ errors });

            // }
        }
        else {
            errors["image"] = "File format not supported";
            this.setState({ errors });
        }

    }

    onSubmitHandler = (dialogType) => {

        let { syllabusInfoObj } = this.state;

        if (this.syllabusFormHandleError()) {

            let response = ''; let msg = '';
            if (dialogType == 'insert') {
                syllabusInfoObj.syllabusSerial = this.state.dataTableValue.length + 1;
                syllabusInfoObj.cmsId = cmsId;
                let { errors } = this.state;
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.DwsService.cmsSaveSyllabusInfo(syllabusInfoObj)
                    .then(res => {
                        // console.log('save-res', res);

                        if (res.status == 201) {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.onHideDialog();
                            this.getTableData();
                            this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Syllabus information successfully saved' });
                        } else {
                            this.DwsService.Auth.handleErrorStatus(res)
                                .then((res) => {
                                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: res })
                                });
                        }
                    })
                    .catch(error => {
                        this.setState({ errors: errors })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });

            } else if (dialogType == 'update') {

                // console.log('update obj', syllabusInfoObj);

                let { errors } = this.state;
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.DwsService.cmsUpdateSyllabusInfo(syllabusInfoObj)
                    .then(res => {

                        if (res.status == 201) {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.onHideDialog();
                            this.getTableData();
                            this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Syllabus information Successfully Updated' });
                        }

                    })
                    .catch(error => {
                        this.setState({ errors: errors })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });
            }
        }

    }

    deleteSyllabus = () => {

        let { errors, selectedRowData } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.DwsService.cmsDeleteSyllabusInfo(selectedRowData.syllabusId)
            .then(res => {

                // console.log('delete-res', res);

                if (res.status == 200) {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    this.onHideDeleteDialog();
                    this.getTableData();
                    this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Syllabus information successfully deleted.' });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((res) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: res })
                        });
                }
            })
            .catch(error => {
                this.setState({ errors: errors })
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    clearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    setRowDataToUpdateDialog = (rowData) => {

        // console.log('rowData', rowData);
        let { uploadFile } = this.state
        uploadFile.fileName = rowData.syllabusFileName;
        uploadFile.fileContent = rowData.fileContent;
        uploadFile.fileSaveOrEditable = true

        let syllabusInfoObj = {
            "classId": rowData.classId,
            "cmsId": Number(cmsId),
            "groupId": rowData.groupId,
            "fileName": rowData.syllabusFileName,
            "fileContent": rowData.fileContent,
            'fileSaveOrEditable': true,
            "syllabusSerial": rowData.syllabusSerial,
            "syllabusId": rowData.syllabusId,
        }

        this.setState({ syllabusInfoObj: syllabusInfoObj, uploadFile });
        this.onChangeToDialogVisible('update')

    }

    actionTemplate = (rowData, column) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                tooltip="Update"
                onClick={(e) => this.setRowDataToUpdateDialog(rowData)}
            >
            </Button>

            <Button
                type="button"
                icon="fas fa-trash"
                tooltip="Delete"
                className="nw-action-button p-button-danger"
                onClick={(e) => this.showDeleteDialog('delete', rowData)}
            />
        </div>;
    }

    render() {

        let { syllabusInfoObj, dataTableValue, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let classOptions = [];
        if ((this.state.classDropDownList != null) && (this.state.classDropDownList.length > 0)) {
            classOptions = this.state.classDropDownList.map((item) => ({
                value: item.classId,
                label: item.className,
            }));
        }

        let groupOptions = [];
        if ((this.state.groupDropDownList != null) && (this.state.groupDropDownList.length > 0)) {
            groupOptions = this.state.groupDropDownList.map((item) => ({
                value: item.groupId,
                label: item.groupName,
            }));
        }

        let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">(Max 2MB (recommended))</span></div>

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Syllabus Info List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue.length.toLocaleString("EN-IN")}</a>
                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={() => this.onChangeToDialogVisible('insert')}
                />
            </div>
        </div>;

        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="syllabusSerial" header="Serial No." filter={true} />
                                    <Column field="className" header="Class" filter={true} />
                                    <Column field="groupName" header="Group" filter={true} />
                                    <Column field="action" header="Action" body={this.actionTemplate} />
                                </DataTable>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header={dialogHeader}
                        visible={this.state.dialogVisible}
                        onHide={this.onHideDialog}
                        closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>

                                                {dialogType == "update" ?
                                                    <InputText
                                                        placeholder="Enter Serial no."
                                                        name="syllabusSerial"
                                                        value={syllabusInfoObj.syllabusSerial}
                                                        onChange={e => this.onChageDropDownValue(e, e.target.value)}
                                                    />
                                                    :
                                                    <div className="nw-inputgroup-desc">
                                                        {syllabusInfoObj.syllabusSerial || this.state.dataTableValue.length + 1}
                                                    </div>

                                                }

                                            </div>
                                            {dialogType == "update" ? <span className="error-message">{errors["syllabusSerial"]}</span> : ''}

                                        </div>

                                        {/* <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup">
                                                    <InputText
                                                        placeholder="Enter Serial No."
                                                        keyfilter="pint"
                                                        name="syllabusSerial"
                                                        value={syllabusInfoObj.syllabusSerial}
                                                        onChange={e => this.onChageDropDownValue(e, e.target.value, dialogType)}
                                                    />
                                                </div>
                                                <span className="error-message">{errors["syllabusSerial"]}</span>
                                            </div>
                                        </div> */}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">class <span> *</span></span>
                                                <Dropdown
                                                    placeholder="Select Class"
                                                    name="classId"
                                                    options={classOptions}
                                                    value={syllabusInfoObj.classId}
                                                    onChange={e => this.onChageDropDownValue(e, e.value, dialogType)}
                                                    autoWidth={false}
                                                    showClear={true}

                                                />
                                            </div>
                                            <span className="error-message">{errors["classId"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Group <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Group"
                                                    name="groupId"
                                                    options={groupOptions}
                                                    value={syllabusInfoObj.groupId}
                                                    onChange={e => this.onChageDropDownValue(e, e.value, dialogType)}
                                                    autoWidth={false}
                                                    showClear={true}

                                                />
                                            </div>
                                            <span className="error-message">{errors["groupId"]}</span>
                                        </div>

                                        {/* <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload File <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.imageInputFieldIsLoading ?
                                                            <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                            this.state.uploadFile.fileContent ?
                                                                <div className="image-view-main">
                                                                    <div className="upload-image-view">
                                                                        <Button
                                                                            className="delete-upload-button"
                                                                            icon="fas fa-times-circle"
                                                                            onClick={this.removeUploadFile}
                                                                        />

                                                                        {
                                                                            this.state.uploadFile.fileName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadFile.fileName)
                                                                                :
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                        }
                                                                      
                                                                    </div>
                                                                    <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                                </div>

                                                                :
                                                                <FileUpload
                                                                    chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                                    accept="image/*, .pdf, .doc,.docx"
                                                                    id="fileUpload"
                                                                    mode="basic"
                                                                    name="uploadFile"
                                                                    onSelect={e => this.onLoadPic(e, 2097152)}
                                                                    auto={true}
                                                                />
                                                    }

                                                </div>
                                            </div>
                                            <span className="error-message">{!this.state.uploadFile.fileName && this.state.errors["image"]}</span>
                                        </div> */}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload File <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.imageInputFieldIsLoading ?
                                                            <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                            this.state.uploadFile.fileContent ?
                                                                <div className="image-view-main">
                                                                    <div className="upload-image-view">
                                                                        <Button
                                                                            className="delete-upload-button"
                                                                            icon="fas fa-times-circle"
                                                                            onClick={this.removeUploadFile}
                                                                        />

                                                                        {
                                                                            this.state.uploadFile.fileName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadFile.fileName)
                                                                                :
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                        }
                                                                        {/* <img
                                                                            src={
                                                                                this.state.uploadFile.fileContent === '' ?
                                                                                    'assets/images/demo.jpg'
                                                                                    :
                                                                                    "data:image/png;base64," + this.state.uploadFile.fileContent
                                                                            }
                                                                            style={{ "height": "80px" }}
                                                                        /> */}

                                                                    </div>
                                                                    <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                                </div>

                                                                :
                                                                <FileUpload
                                                                    chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                                    accept="image/*, .pdf, .doc,.docx"
                                                                    id="fileUpload"
                                                                    mode="advanced"
                                                                    maxFileSize="2000000"
                                                                    name="uploadFile"
                                                                    onSelect={e => this.onLoadPic(e, 2000000)}
                                                                    auto={true}
                                                                />
                                                    }

                                                </div>

                                                {/* <center>
                                                {this.state.uploadFile.fileName}
                                            </center> */}
                                            </div>
                                            <Messages ref={(el) => this.messages = el}></Messages>
                                            <span className="error-message">{!this.state.uploadFile.fileName && this.state.errors["image"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                    </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                label={dialogType == "insert" ? "Save" : "Update"}
                                                icon="fas fa-check"
                                                onClick={() => this.onSubmitHandler(dialogType)}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Delete Syllabus" visible={this.state.deleteDialogVisible} onHide={this.onHideDeleteDialog} className="nw-confirm-dialog">
                        <div className="p-fluid">
                            <div className="p-col-12 p-col-12">
                                <div className="confirm-wrapper">
                                    {/* <div className="body-wrapper">
                                <header>Total messages you are about to send</header>
                                <h1>{selectedCustomers.length}</h1>
                                <footer>{selectedCustomers.length}  Person x {selectedCustomers.length} Message</footer>
                            </div> */}
                                    <h1>Are You Sure?</h1>
                                    <div className="nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="No"
                                            icon="fas fa-times"
                                            onClick={this.onHideDeleteDialog}
                                        />
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Yes"
                                            icon="fas fa-check"
                                            onClick={(e) => this.deleteSyllabus()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                </div>
            </div>
        )
    }
}