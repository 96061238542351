import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { CUSTOM_MESSAGE } from '../../common/CustomMessage';
import CommonFuctionality from '../../common/CommonFuctionality';

let urlId;
let page = 0;
export class MemberInfoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberPicture: '',
            dataTableValue: [],
            proPic: {},
            updateMemberInfoError: {},
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            dataTableIsLoading: false,
            institutePicture: '',
            updateMemberDetails: {
                memberID: 0,
                memberSerial: '',
                memberType: '',
                memberName: '',
                memberMobile: '',
                memberDesignation: '',
                memberEmail: '',
                facebookProfile: '',
                linkedinProfile: '',
                twitterProfile: '',
                memberStatus: '',
                coreUrlInfoDTO: {
                    urlInfoID: 1
                }

            },
            first: 0,
            rows: 10,
        }

        this.dwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
        this.CommonFuctionality = new CommonFuctionality();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {

        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        this.viewMemberInfoList();
    }

    viewMemberInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        let coreUrl = {
            "coreUrlInfoID": urlId,
            "pageNo": page,
            "limit": this.state.rows
        }

        this.dwsService.getDwsMemberUrlInfoList(coreUrl)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.props.countTableSize(body.length);
                        this.setState({ dataTableValue: body, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                    });
                } else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    countTableSize() {
        const { dataTableValue } = this.state;
        return dataTableValue.length;
    }

    onHide = (event) => {
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    memberDetails = (rowData) => {
        let { updateMemberDetails } = this.state;
        this.viewDialog();
        this.setState({ updateMemberDetails: { ...rowData } });

        let staticImg = 'assets/layout/images/avatar.png';
        if (rowData.memberImgPath !== undefined) {
            this.netiFileHandler.getByteImage(rowData.memberImgPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(rowData.memberImgName);
                                this.setState({ memberPicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ memberPicture: staticImg });
                    }
                });
        } else {
            this.setState({ memberPicture: staticImg });
        }
    }

    actionTemplate = (rowData) => {

        let deleteBtn = ''
        let btnIcon= ''
        let tooltipMsg=''
        if(rowData.memberStatus === 1){
            deleteBtn = "p-button-success";
            btnIcon= "fas fa-check"
            tooltipMsg="Click to Inactive"
        }
        else if(rowData.memberStatus === 0){
            deleteBtn = "p-button-danger";
            btnIcon= "fas fa-minus-circle"
            tooltipMsg='Click to Active'
        }
        
        return <div className="text-center">
            <span style={{ marginRight: '5px' }}>
                <Button
                    type="button"
                    icon="fas fa-pencil-alt"
                    className="nw-action-button"
                    onClick={(e) => this.memberDetails(rowData)}
                    tooltip="Edit"
                />
            </span>
            
            <span>
                <Button
                    type="button"
                    icon={btnIcon}
                    className={"nw-action-button " + deleteBtn}
                    onClick={(e) => this.deleteMemberRowData(rowData)}
                    tooltip={ tooltipMsg }
                />
            </span>
            
        </div>;
    }

    deleteMemberRowData = (rowData) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

        this.dwsService.deleteMemberInfo(rowData.memberID)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });

                    this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: CUSTOM_MESSAGE.SUCCESS_MSG_LONG_UPDATE })

                    setTimeout(() => {
                        this.viewMemberInfoList();
                    }, 1000);
                    
                } else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
            });

    }

    onChangeMemberSerial = (e) => {
        let { updateMemberDetails } = this.state;
        updateMemberDetails.memberSerial = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onChangeMemberType = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.memberType = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onChangeMemberName = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.memberName = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onChangeMemberDesignation = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.memberDesignation = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onChangeMemberMobile = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.memberMobile = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onChangeMemberEmail = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.memberEmail = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onImageUpload(e) {
        this.emptyErrorMessage()
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });

        }
    }

    onChangeFbLink = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.facebookProfile = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onChangeLinkedinLink = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.linkedinProfile = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onChangeTwitterLink = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.twitterProfile = e.target.value;
        this.setState({ updateMemberDetails });
    }

    onChangeMemberStatus = (e) => {
        this.emptyErrorMessage()
        let { updateMemberDetails } = this.state;
        updateMemberDetails.memberStatus = parseInt(e.target.value);
        this.setState({ updateMemberDetails });
    }

    emptyErrorMessage() {
        let { updateMemberInfoError } = this.state
        updateMemberInfoError = {}
        this.setState({ updateMemberInfoError });
    }

    onUpdateHandler = () => {
        let { updateMemberDetails } = this.state;
        if (this.state.proPic.contentPic) {
            updateMemberDetails.imageContent = this.state.proPic.contentPic;
            updateMemberDetails.imageSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            // updateMemberDetails.memberImgName = Date.now() + extention; //this.state.proPic.contentName
            updateMemberDetails.memberImgName = this.state.proPic.contentName;
        }
        if (this.updateMemberFormHandleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.setState({ updateMemberDetails });
            this.dwsService.updateDwsMemberInfo(updateMemberDetails)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated", life: 800 });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                        this.viewMemberInfoList();
                    } else {
                        this.dwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });

        }

    }

    updateMemberFormHandleError = () => {
        let { updateMemberInfoError, updateMemberDetails } = this.state;
        let formIsValid = true;

        if (updateMemberDetails.memberSerial === '') {
            formIsValid = false;
            updateMemberInfoError["serialNumber"] = "Serial Number can't left empty.";
        }
        if (!updateMemberDetails.memberType) {
            formIsValid = false;
            updateMemberInfoError["type"] = "Type can't left empty.";
        }
        if (updateMemberDetails.memberName === '') {
            formIsValid = false;
            updateMemberInfoError["name"] = "Name can't left empty.";
        }
        if (updateMemberDetails.memberDesignation === '') {
            formIsValid = false;
            updateMemberInfoError["memberDesignation"] = "Designation can't left empty.";
        }
        if (updateMemberDetails.memberMobile === '') {
            formIsValid = false;
            updateMemberInfoError["mobileNo"] = "Mobile number can't left empty.";
        }
        if (updateMemberDetails.memberEmail === '') {
            formIsValid = false;
            updateMemberInfoError["email"] = "Email can't left empty.";
        }
        // if (updateMemberDetails.facebookProfile === '') {
        //     formIsValid = false;
        //     updateMemberInfoError["fbLink"] = "Facebook link can't left empty.";
        // }
        if (updateMemberDetails.memberImgName === '') {
            formIsValid = false;
            updateMemberInfoError["attachments"] = "Attachment is missing.";
        }
        // if (updateMemberDetails.linkedinProfile === '') {
        //     formIsValid = false;
        //     updateMemberInfoError["linkedinLink"] = "Linkedin link can't left empty.";
        // }
        // if (updateMemberDetails.twitterProfile === '') {
        //     formIsValid = false;
        //     updateMemberInfoError["twitterLink"] = "twitter link can't left empty.";
        // }
        this.setState({ updateMemberInfoError });
        return formIsValid;
    }

    onPageChange = (event) => {
        page = event.page;
        this.setState({
            first: event.first,
            rows: event.rows

        });

        this.viewMemberInfoList();
    }

    memberStatusSelector = (memberStatus) => {
        return (
            <div className="input-radio-button">
                {/* <div className="radio-button-inside">
                    <RadioButton
                        value="Holiday"
                        inputId="rb1"
                        checked={eventType === "Holiday"}
                        onChange={this.onChangeEventType}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Holiday</label>
                </div> */}

                <div className="radio-button-inside">
                    <RadioButton
                        value="1"
                        inputId="rb1"
                        checked={memberStatus === 1}
                        onChange={this.onChangeMemberStatus}
                    />
                    <label htmlFor="rb2" className="p-radiobutton-label">Enable</label>
                </div>
                <div className="radio-button-inside">
                    <RadioButton
                        value="0"
                        inputId="rb1"
                        checked={memberStatus === 0}
                        onChange={this.onChangeMemberStatus}
                    />
                    <label htmlFor="rb2" className="p-radiobutton-label">Disable</label>
                </div>
            </div>
        )
    }

    render() {
        let { updateMemberDetails, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let memberListOption = [
            { label: 'Committee', value: 'Committee' },
            { label: 'Donor', value: 'Donor' },
        ]
        let title = <span className="text-center"><span>Update Member Information</span></span>
        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Member Info List
            <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue.length}</span>
        </div>;
        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12 blur-section">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <div>
                                    <DataTable
                                        value={this.state.dataTableValue}
                                        selectionMode="single"
                                        header={tableHeader}
                                        selection={this.state.dataTableSelection}
                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                        responsive={true}
                                        rows={10}
                                        columnResizeMode="fit"
                                        paginator={true}
                                    >
                                        <Column field="memberSerial" header="Serial No." filter={true} />
                                        <Column field="memberType" header="Type" filter={true} />
                                        <Column field="memberName" header="Name" filter={true} />
                                        <Column field="memberDesignation" header="Designation" filter={true} />
                                        <Column field="memberMobile" header="Mobile No." filter={true} />
                                        <Column field="action" header="Action" body={this.actionTemplate} style={{ width: '120px' }} />
                                    </DataTable>

                                    {/* <Paginator
                                        first={this.state.first}
                                        rows={this.state.rows}
                                        totalRecords={this.state.first + 10}
                                        // rowsPerPageOptions={[10,20,30]}
                                        onPageChange={this.onPageChange}>

                                    </Paginator> */}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="dialog-section">
                        <Dialog className="nw-dialog" header={title} visible={this.state.visible} onHide={this.onHide} closable>

                            <div className="p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form">
                                            <div className="p-col-12 p-xl-12">
                                                
                                                <div className="p-grid nw-form-body">

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Serial No.</span>
                                                            <div className="nw-inputgroup-desc">
                                                                {updateMemberDetails.memberSerial}
                                                            </div>
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["serialNumber"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Type <span>*</span></span>
                                                            <Dropdown
                                                                id="type"
                                                                placeholder="Select member type"
                                                                value={updateMemberDetails.memberType}
                                                                options={memberListOption}
                                                                onChange={(e) => this.onChangeMemberType(e)}
                                                                filter={true}
                                                                filterBy="label,value"
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="type"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["type"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Name <span>*</span></span>
                                                            <InputText
                                                                value={updateMemberDetails.memberName}
                                                                onChange={(e) => this.onChangeMemberName(e)}
                                                                placeholder="Enter member name"
                                                                type="text"
                                                                id="name"
                                                                name="requestedAmmount"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["name"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Designation<span>*</span></span>
                                                            <InputText
                                                                id="designation"
                                                                onChange={(e) => this.onChangeMemberDesignation(e)}
                                                                value={updateMemberDetails.memberDesignation}
                                                                placeholder="Enter member designation"
                                                                type="text"
                                                                name="memberDesignation"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["memberDesignation"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Mobile No. <span>*</span></span>
                                                            <InputText
                                                                id="mobileNo"
                                                                onChange={(e) => this.onChangeMemberMobile(e)}
                                                                value={updateMemberDetails.memberMobile}
                                                                placeholder="Enter member mobile number"
                                                                keyfilter="pint"
                                                                maxLength={11}
                                                                type="text"
                                                                name="mobileNo"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["mobileNo"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Email <span>*</span></span>
                                                            <InputText
                                                                id="email"
                                                                onChange={(e) => this.onChangeMemberEmail(e)}
                                                                value={updateMemberDetails.memberEmail}
                                                                placeholder="Enter member email address"
                                                                type="text"
                                                                name="email"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["email"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Facebook Link </span>
                                                            <InputText
                                                                onChange={(e) => this.onChangeFbLink(e)}
                                                                value={updateMemberDetails.facebookProfile}
                                                                placeholder="Enter facebook link"
                                                                type="text"
                                                                id="fbLink"
                                                                name="fbLink"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["fbLink"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Linkedin Link </span>
                                                            <InputText
                                                                onChange={(e) => this.onChangeLinkedinLink(e)}
                                                                value={updateMemberDetails.linkedinProfile}
                                                                placeholder="Enter linkedin link"
                                                                type="text"
                                                                id="linkedinLink"
                                                                name="linkedinLink"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["linkedinLink"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Twitter Link </span>
                                                            <InputText
                                                                onChange={(e) => this.onChangeTwitterLink(e)}
                                                                value={updateMemberDetails.twitterProfile}
                                                                placeholder="Enter twitter link"
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                id="twitterLink"
                                                                name="twitterLink"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["twitterLink"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon addon-no-style">Type <span>*</span></span>
                                                            
                                                            <div className="nw-inputgroup-desc desc-no-style">{this.memberStatusSelector(updateMemberDetails.memberStatus)}</div>

                                                        </div>
                                                        {/* <span className="error-message">{this.state.photoSubmitError.photoType}</span> */}
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup nw-upload-button">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                                Upload Image <span>*</span>
                                                                <br />
                                                                <span>(PNG/JPG/DOC/PDF)</span>
                                                            </span>

                                                            <div className="nw-upload-button-inside">
                                                                <div className="image-view-main">
                                                                    <div className="upload-image-view">
                                                                        <Button
                                                                            className="delete-upload-button"
                                                                            icon="fas fa-times-circle"
                                                                            onClick={e => this.setState({ proPic: { contentPic: '' } })}
                                                                        />
                                                                        <img
                                                                            src={
                                                                                this.state.proPic.contentPic ?
                                                                                    "data:image/png;base64," + this.state.proPic.contentPic
                                                                                    :
                                                                                    this.state.memberPicture
                                                                            }
                                                                            style={{ "height": "80px" }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <FileUpload
                                                                    chooseLabel={this.state.proPic.contentName ? this.state.proPic.contentName : this.state.updateMemberDetails.memberImgName}
                                                                    mode="basic"
                                                                    accept="image/*"
                                                                    maxFileSize={1000000}
                                                                    onSelect={this.onImageUpload.bind(this)}
                                                                    auto={true}
                                                                />

                                                            </div>
                                                        </div>
                                                        <span className="error-message">{this.state.updateMemberInfoError["attachments"]}</span>
                                                    </div>

                                                    <div className="p-grid p-col-12 p-xl-12 nw-button-parent-multiple">
                                                        <Button
                                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                                            label="Discard"
                                                            icon="fas fa-times"
                                                            onClick={this.onHide}
                                                        />
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                                            label="Update"
                                                            icon="fas fa-check"
                                                            onClick={this.onUpdateHandler}
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}