import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { ProfileService } from '../../../service/profile/ProfileService'; 
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import AuthService from '../../../auth/AuthService';
import NetiContentLoader from '../common/NetiContentLoader'
import { MY_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';

const Auth=new AuthService();


let errors = {};
export class ChangePassword extends Component {
    constructor(props) {

        super(props)
        this.state = {
            passwordReturn: '12345678',
            emptyPassword: false,
            passwordSet: '',
            newPasswordSet: '',
            reTypePassword: '',
            emptyRetypePassword:false,
            passwordIcon: true,
            hidden: true,
            passWordmatch: true,
            newOldPasswordMatch: false,
            passwordLengthCheck: false,
            worngPassword: false,
            successMessage:false,
            checkEligibility: false,
            renderLogoutDialog: false,
            errors:{},
            renderLogoutDialog: false,
            topProgressBar: false
        }

        this.profileService = new ProfileService();
        this.NetiContentLoader = new NetiContentLoader()

        this.onChangeHandlePassword = this.onChangeHandlePassword.bind(this)
        this.onChangeHandleRetypePassword = this.onChangeHandleRetypePassword.bind(this)
        this.togglePasswordShow = this.togglePasswordShow.bind(this)
        this.onChangeHandleNewPassword = this.onChangeHandleNewPassword.bind(this)
        this.showWarn = this.showWarn.bind(this);


    }

    componentDidMount() {   
        this.setState({topProgressBar: true, errorMsgVisible: false});
        this.profileService.checkUserNameChangeEligibility()
            .then((res)=>{
                if(res.status == 200) {
                    return res.json().then((body) => {
                        if(body === true){
                            this.setState({checkEligibility: true});
                            this.setState({topProgressBar: false});
                        }
                        else{
                            this.setState({topProgressBar: false});
                            this.setState({checkEligibility: false});
                        }
                    });
                }
                else{
                    this.profileService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ checkEligibility: false });
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
                }
                
            }).catch((error) => {
                this.setState({ secondDropDownIsLoading:false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
            
    }

    showWarn(msg) {
        this.growl.show({severity: 'warn', summary: 'Warning', detail: msg});
    }

    onChangeHandlePassword = (event, props) => {
        errors={}
        this.setState({
            passwordSet: event.target.value
        });
        let passwordSet = event.target.value
        let passwordReturn = this.state.passwordReturn
        if (passwordSet === '') {

            errors['passwordMatchError'] = "Please fill the field"
            this.setState({
                passwordSet: event.target.value,
                worngPassword: false
            });
        }
        else {
            errors['passwordMatchError'] = ""
            this.setState({
                worngPassword: true
            });
        }

    }

    onChangeHandleNewPassword = (event, props) => {
        errors={}
        this.setState({
                newPasswordSet: event.target.value
            });
        if(this.state.passwordSet === event.target.value){
            errors["newPasswordError"] = "New password match with old"

        }else{
            errors["newPasswordError"] = ""
        }
        
    }

    onChangeHandleRetypePassword = (event, props) => {
        this.setState({
            reTypePassword: event.target.value
        });

        let password = this.state.newPasswordSet
        let rePassword = event.target.value
        if (password === rePassword) {
            errors['newPasswordMatchError'] = ""
            this.setState({
                passWordmatch: true
            });
        }
        else {
            errors['newPasswordMatchError'] = "Password didn't match with new"
            this.setState({
                passWordmatch: false
            });
        }

    }

    togglePasswordShow(event, props) {
        this.setState({
            hidden: !this.state.hidden,
            passwordIcon: !this.state.passwordIcon
        });

    }

    logoutTimer(){
        this.setState({renderLogoutDialog: true})
        setTimeout(function() { //Start the timer
            this.setState({renderLogoutDialog: false}) //After 5 second, set render to true
            Auth.logout();
            window.location.href="/";
        }.bind(this), 5000)
    }

    handleError(){
        let passwordSet = this.state.passwordSet
        let newPassword = this.state.newPasswordSet
        let oldPassword = this.state.passwordReturn
        let reTypePassword = this.state.reTypePassword
        errors = {};
        let formIsValid = true;

        if(passwordSet === ''){
            errors['passwordMatchError'] = "Please fill the field"
            formIsValid = false
        }
        else if(newPassword === ''){
            errors["newPasswordError"] = "Please fill the field"
            formIsValid = false
        }
        else if(reTypePassword === ''){
            this.setState({
                passWordmatch: false
            });
            formIsValid = false
        }
        else if(passwordSet === newPassword){
            errors["newPasswordError"] = "New password match with old"
            formIsValid = false
        }
        else if((newPassword === reTypePassword) && (newPassword != passwordSet)){
            errors["newPasswordError"] = "";
            return formIsValid
        }

        this.setState({errors: errors});
        
    }

    passwordChangeOnSubmitHandle(){

        if(this.handleError() === true){
            this.setState({topProgressBar: true});   

            let setPassword = this.state.passwordSet;
            let newSetPassword = this.state.newPasswordSet

            let userPasswordInfo = {
                "password": setPassword,
                "newPassword": newSetPassword
            }

            

            this.profileService.checkPasswordForUpdate(userPasswordInfo)
            .then((res)=>{
                    if(res.status === 200){
                        return res.json().then((body) => {
                            if(body === false){
                                errors['passwordMatchError'] = "Please check password again"
                                this.showWarn(errors['passwordMatchError'])
                            }
                            else{
                                this.profileService.updateUserPassword(userPasswordInfo)
                                .then((res)=>{
                                    if(res.status === 200){
                                        errors['passwordMatchError'] = ''
                                        this.growl.show({severity: 'success', summary: 'Success', detail: 'Passwword Changed'});
                                        this.setState({
                                            successMessage:true
                                        })
                                        this.setState({topProgressBar: false});   
                                        this.logoutTimer();
                                    }
                                     
                                }).catch((error) => console.log('error', error));

                            }

                            this.setState({errors: errors})
                        });
                    }
                    else if(res.status === 400){
                        return res.json().then((body) => {
                                this.setState({errorMSG: body.message})
                                this.showWarn(this.state.errorMSG)
                            
                        });
                    }
                    else{
                        errors["passwordSet"]='Invalid Password'
                        this.growl.show({severity: 'warn', summary: 'Warning', detail: 'Please check password again'});
                        this.setState({
                            errors: errors
                        })
                        // this.showWarn("Check Password Again")
                    }
            }).catch((error) => {
                this.growl.show({severity: 'warn', summary: 'Warning', detail: 'Unable to save, Please try again'});
                
            });
        }
        else{
            errors["passwordSet"]="Please Input Your Password"
        }
        
        this.setState({
            errors: errors
        })
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let renderLogoutDialogContainer = '' //By default don't render anything
        if(this.state.renderLogoutDialog) { //If this.state.render == true, which is set to true by the timer.
            renderLogoutDialogContainer = <div>
                <Dialog 
                    header="Please Login again" 
                    className="nonHideDialog"
                    visible={this.state.renderLogoutDialog}
                    closeOnEscape={false}
                    closable={false}
                    onHide={this.dialogOnHide}
                >
                    <div className="text-center">
                        {/* <br /> */}
                        <b>Please wait...</b> Logging Out
                        <br />
                        <br/>
                        <Message 
                            severity="success" 
                            summary= 'Redirecting to Login'
                            text=" Please wait... " 
                        />
                        <br />
                        {/* {
                            this.state.checkEligibility === true ?
                                <Link to={MY_POINT.CHANGE_USER_NAME}>Change Username</Link>
                                :
                                <b>You have already reached your limit to change User Name</b>
                        } */}

                    </div>
                    <br />
                </Dialog>
            </div> //Add dom elements
        }

        else {
            renderLogoutDialogContainer = ''//Render the dom elements, or, when this.state == false, nothing.
        }


        return (
            <div className="p-fluid">
                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">

                { renderLogoutDialogContainer }

                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                                <div className="p-col-12 p-xl-12">
                                    <div className='nw-form-body'>
                                        <div className="p-grid seprator-inside">
                                            <div className="p-col-12 ">
                                                <label><b>Reset current password</b></label>
                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Current Password</span>
                                                    <InputText
                                                        type={this.state.hidden ? "password" : "text"}
                                                        name="password"
                                                        placeholder="Enter current password"
                                                        onChange={(event) => this.onChangeHandlePassword(event, this.props)}
                                                        required="required"
                                                        keyfilter="alphanum"
                                                        tooltip="" tooltipOptions={{ showDelay: 1000, hideDelay: 300 }}
                                                    />
                                                    <Button
                                                        icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                                        className=""
                                                        onClick={(event) => this.togglePasswordShow(event, this.props)} />
                                                </div>
                                                <span className='error-message'>{errors["passwordMatchError"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">New Password</span>
                                                    <InputText
                                                        type={this.state.hidden ? "password" : "text"}
                                                        name="password"
                                                        className="custom-field-control"
                                                        placeholder="Enter new password"
                                                        onChange={(event) => this.onChangeHandleNewPassword(event, this.props)}
                                                        required="required"
                                                        keyfilter="alphanum"
                                                    />
                                                    <Button
                                                        icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                                        className=""
                                                        onClick={(event) => this.togglePasswordShow(event, this.props)} />
                                                </div>
                                                <span className='error-message'>{errors["newPasswordError"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Re-type Password</span>
                                                    <InputText
                                                        type={this.state.hidden ? "password" : "text"}
                                                        name="re-password"
                                                        className={this.state.passWordmatch === false ? 'custom-field-control custom-error-field' : 'custom-field-control'}
                                                        placeholder="Re-type password"
                                                        onChange={(event) => this.onChangeHandleRetypePassword(event, this.props)}
                                                        required="required"
                                                    />
                                                    <Button
                                                        icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                                        className=""
                                                        onClick={(event) => this.togglePasswordShow(event, this.props)}
                                                    />
                                                </div>
                                                <span className='error-message'>{errors["newPasswordMatchError"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label="Update"
                                                    icon="fas fa-check"
                                                    onClick={this.passwordChangeOnSubmitHandle.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-4">
                                <div className="card card-w-title statistics">
                                    <br /><br /><br />
                                    <div className="text-center">
                                        <Message severity="warn" text="N.B Please remember that, according to our company policy, you are allowed to change your username only once." />
                                        <br /><br />
                                        To change your login username,please click the link below.
                                        <br /><br />

                                        {/* <center>
                                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
                                        </center> */}
                                        {
                                            this.state.topProgressBar?
                                                this.NetiContentLoader.MyLoader()
                                                :
                                                this.state.checkEligibility === true ?
                                                <Link to={MY_POINT.CHANGE_USER_NAME} >Change Username</Link>
                                                :
                                                <b>You have already reached your limit to change User Name</b>
                                        }

                                    </div>
                                    <br /><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}