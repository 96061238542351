import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { WalletBalance } from '../common/WalletBalance';
import { PurchaseService } from '../../../service/myPoint/PurchaseService'
import { NetiDateUtils } from '../../../utils/NetiDateUtils'
import NetiContentLoader from '../../common/NetiContentLoader';
import { MY_POINT } from '../../../utils/PointWiseRouteConsts';
import AnimatedNumber from 'react-animated-number';
import discountPercentSeventy from '../../../assets/images/mypoint/discount_70%.png'
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';

let errors = {}
export class PurchaseOfferProduct extends Component {

    constructor() {
        super();
        this.state = {
            productOfferObj: {
                actualPrice: 0,
                discountPercent: 0
            },
            purchesPoint: null,
            purchaseCodeListTable: [],
            productType: null,
            productName: null,
            productOfferID: null,
            offerCode: '',
            actualPrice: 0,
            discountPercent: null,
            offerStartDate: null,
            offerQuantity: null,
            offerUseableTime: null,
            offerNote: null,
            offerUsed: null,
            dialogPurchesPoint: null,
            dialogProductType: null,
            dialogProductName: null,
            dialogProductDate: null,
            dialogProductQuantity: null,
            dialogOfferCode: null,

            dataTableSelection: null,
            dataTableValue: [],
            errors: null,
            topProgressBar: false,
            dataTableIsLoading: false,
            dialogDataTableIsLoading: false,
            errorInfo: false,
            dataIsLoading: false,
            searchView: true,
            searchViewError: false,
            buttonDisabled: true
        }

        this.purchaseService = new PurchaseService();
        this.purchaseCodeBody = this.purchaseCodeBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.searchOfferConfigByOfferCode = this.searchOfferConfigByOfferCode.bind(this);
        this.offerProductOnSubmit = this.offerProductOnSubmit.bind(this);
        this.getOfferProductTopTen = this.getOfferProductTopTen.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
    }

    componentDidMount() {
        this.getOfferProductTopTen();
    }

    submitButtonCheck() {
        if (this.state.offerCode) {
            this.setState({ buttonDisabled: false })
        }
        else {
            this.setState({ buttonDisabled: true })
        }
    }

    getOfferProductTopTen() {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.purchaseService.fetchTopTenOfferProduct()
            .then((res) => {

                if (res.status === 302) {
                    return res.json()
                        .then((body) => {
                            console.log(body);
                            
                            for (let i = 0; i < body.length; i++) {
                                if (body[i].offerUseDate == null) {
                                    body[i].offerUseDate = '';
                                } else {
                                    body[i].offerUseDateTime = NetiDateUtils.getHHMMSSFForHome(body[i].offerUseDate);
                                    body[i].offerUseDate = NetiDateUtils.getAnyShortForm(body[i].offerUseDate, 'DD-MMM-YYYY hh:mm:ss a');
                                    body[i].payableAmount = body[i].payableAmount.toFixed(2)
                                    body[i].totalPrice = body[i].totalPrice.toFixed(2)
                                    body[i].totalDiscount = body[i].totalDiscount.toFixed(2)
                                }
                            }
                            this.setState({ dataTableValue: body, dataTableIsLoading: false, topProgressBar: false })
                        })
                }
                else {
                    this.purchaseService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }

            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
    }

    viewDialog(rowData) {
        this.setState({ purchaseCodeListTable: [] });
        let formatedOfferUseDate = NetiDateUtils.getAnyShortForm(rowData.offerUseDate, 'DD-MMM-YYYY');

        this.setState({ dialogpurchasePoint: rowData.purchasePoint })
        this.setState({ dialogProductType: rowData.productType })
        this.setState({ dialogProductName: rowData.productName })
        this.setState({ dialogProductQuantity: rowData.productQuantity })
        this.setState({ dialogOfferCode: rowData.offerCode })
        this.setState({ dialogProductDate: formatedOfferUseDate })
        this.setState({ purchaseProductId: rowData.purchaseProductId })
        this.setState({ visible: true });

        this.setState({ topProgressBar: true, dialogDataTableIsLoading: true, errorMsgVisible: false })
        this.purchaseService.fetchProductPurchaseCodeDetailsByPurchaseId(rowData.productPurchaseLogID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ purchaseCodeListTable: body });
                        this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: false })
                    });
                }
                else {
                    this.purchaseService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
    }

    usedDateBody = (rowData) => {
        return (
            <span>
                {rowData.usedDate != null ? NetiDateUtils.getAnyShortForm(rowData.usedDate, 'DD-MMM-YYYY') : ''}

            </span>
        );
    }

    statusCheck = (rowData) => {
        return (
            <span>
                {rowData.usedStatus == 0 ? "Unused" : ''}
                {rowData.usedStatus == 1 ? "Used" : ''}
            </span>
        )
    }

    onHide(event) {
        this.setState({ visible: false });
    }

    
    purchaseCodeBody(rowData, column) {

        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title="View Purchase"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    searchOfferConfigByOfferCode() {

        this.setState({ productOfferObj: {} });

        if (!this.state.errorInfo) {
            var element1 = document.getElementById("discount-percent");
            element1.classList.add("discount-percent-bg");
        }

        if (this.state.offerCode !== '') {
            errors['codeSearchError'] = '';
            this.setState({ topProgressBar: true, dataIsLoading: true, errorMsgVisible: false, searchView: true, searchViewError: false });
            this.purchaseService.checkOfferProductByCode(this.state.offerCode)
                .then((res) => {

                    if (res.status === 302) {
                        errors['codeSearchError'] = ''
                        return res.json()
                            .then((body) => {
                
                                this.setState({ productOfferObj: body });
                                this.setState({ errorInfo: false })
                                // this.growl.show({ severity: 'success', summary: 'Success', detail: 'Fetching Data' });
                                this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: false, searchViewError: false });

                                this.submitButtonCheck()

                            });
                    }
                    else {
                        this.purchaseService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                let { productOfferObj } = this.state

                                productOfferObj.discountPercent = 0;
                                errors['codeSearchError'] = resp;

                                this.setState({ productOfferObj, dataIsLoading: false, topProgressBar: false, searchViewError: true })

                                this.submitButtonCheck()
                            });
                    }
                }).catch(error => {
                    this.setState({ searchViewError: false, searchView: false, dataIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' });
                });

        }
        else {
            errors['codeSearchError'] = 'Please input Code';
            this.state.buttonDisabled = true;
            this.state.searchViewError = false;

        }
        this.setState({ errors: errors['codeSearchError'] })
    }



    offerProductOnSubmit() {
        if (this.state.offerCode !== '') {
            let { productOfferObj } = this.state;
            let totalAmount = (productOfferObj.actualPrice * productOfferObj.offerQuantity);
            // let discountAmount = (totalAmount * (productOfferObj.discountPercent)/100);

            let discountAmount = (totalAmount * productOfferObj.discountPercent) / 100;
            let payableAmount = totalAmount - discountAmount;


            let offerProductValue = {
                "actualUnitPrice": productOfferObj.actualPrice,
                // "totalAmount": totalAmount,
                "payableAmount": payableAmount,
                "totalDiscount": discountAmount,
                "totalPrice": totalAmount,
               // "totalPrice": totalAmount,
                "productOfferDTO": {
                    "productOfferID": productOfferObj.productOfferID
                }
            }

            this.setState({ topProgressBar: true,errorMsgVisible: false });
            this.purchaseService.submitOfferProductByCode(offerProductValue)
                .then((res) => {
                    
                    if (res.status === 200) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Save Data' });

                        this.setState({ 
                            offerCode : '',
                            productOfferObj: {

                            }

                        });
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.getOfferProductTopTen();
                    }
                    else {
                        this.purchaseService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                errors['codeSearchError'] = resp;
                                this.setState({ errorInfo: true, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' });
                });
        }
        else {
            errors['codeSearchError'] = 'Please input Code';
        }
        this.setState({ errors: errors['codeSearchError'] })
    }

    prettyBytes(e) {
        return parseFloat(e).toFixed(0)
    }

    totalAmountTemplate = (rowData) => {
      
        let totalValue = (parseInt(rowData.totalPrice) + parseInt(rowData.totalDiscount))
        return this.ValidatorUtility.currencyFormatter(totalValue)
    }

    productQuantityTemplate = (rowData) => {
        return rowData.productQuantity.toLocaleString("EN-IN");
    }

    discountTemplate = (rowData) => {
        return `${this.ValidatorUtility.currencyFormatter(rowData.totalDiscount)} (${rowData.discountPercent}%)`
    }

    paidAmountTemplate = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.payableAmount)
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { productOfferObj } = this.state;


        let totalAmount = (productOfferObj.actualPrice * productOfferObj.offerQuantity);
        let discountAmount = (totalAmount * productOfferObj.discountPercent) / 100;
        let payableAmount = totalAmount - discountAmount;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Offer Product Purchase List
            </div>

            <div className="header-title-right">

                <Link to={{ pathname: MY_POINT.PURCHASE_LOG_OFFER }}>
                    More<i className="fas fa-angle-right" ></i>
                </Link>
            </div>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">
                                <div className="p-grid seprator-inside">
                                    <div className=" p-grid p-col-12 p-xl-12">

                                        <div className="p-col-12 p-lg-9 p-xl-9">
                                            <div className="p-col-12 p-lg-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Offer Code</span>
                                                    <InputText
                                                        name="productName"
                                                        value={this.state.offerCode}
                                                        onChange={e => this.setState({ offerCode: e.target.value })}
                                                        autowidth={'false'}
                                                        placeholder="Type code here"
                                                    />
                                                    <Button
                                                        className=""
                                                        label=""
                                                        icon="fas fa-search"
                                                        onClick={this.searchOfferConfigByOfferCode}
                                                    />
                                                </div>
                                                <span className='error-message'>{this.state.errors}</span>
                                            </div>

                                            {this.state.searchView ?

                                                <div className="p-col-12 p-lg-12 p-xl-12">

                                                    <div class="nw-search-view">
                                                        {this.state.searchViewError ?
                                                            <center className="error-message" style={{ paddingTop: "85px", fontSize: "20px" }}>{errors['codeSearchError'] || "No Data Found"}</center> :
                                                            <div>
                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Product Name</span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.productOfferObj.productName || "-"}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon"> Offer Code </span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.productOfferObj.offerCode || "-"}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon"> Code Useable Time </span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            {/* <span className="p-inputgroup-addon nw-inputgroup-label nw-inputgroup-small-span">
                                                                                    Code Useable
                                                                                </span> */}
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.productOfferObj.offerUseableTime || "-"}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon"> Code Used </span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.productOfferObj.offerUsed || "-"}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon"> Offer Quantity </span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.productOfferObj.offerQuantity || "-"}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon"> Product Unit Price </span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.productOfferObj.actualPrice ?  this.ValidatorUtility.currencyFormatter(this.state.productOfferObj.actualPrice) : "-"}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Total Amount</span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div className="nw-inputgroup-desc">
                                                                                {totalAmount ? this.ValidatorUtility.currencyFormatter(totalAmount) : "-"}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Discount Amount</span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div className="nw-inputgroup-desc">
                                                                                {discountAmount ? this.ValidatorUtility.currencyFormatter(discountAmount) : "-" }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                

                                                                <div className="p-col-12 p-xl-12">
                                                                    {this.state.dataIsLoading ?
                                                                        this.NetiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon"> Payable Amount</span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div className="nw-inputgroup-desc">
                                                                                {payableAmount ? this.ValidatorUtility.currencyFormatter(payableAmount) : '-'}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                               

                                                                <div className="p-col-12 p-xl-12">

                                                                </div>

                                                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                                                    <div className="required-field">

                                                                    </div>


                                                                    <Button
                                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                                        label="Submit"
                                                                        icon="fas fa-check"
                                                                        onClick={this.offerProductOnSubmit}
                                                                        disabled={this.state.buttonDisabled}
                                                                    />
                                                                </div>

                                                            </div>
                                                        }




                                                    </div>

                                                </div>
                                                : ""
                                            }

                                        </div>

                                        <div className="p-col-12 p-lg-3 p-xl-3">
                                            <div className="">
                                                {/* {this.state.errorInfo?
                                                        "": */}
                                                <div id="discount-percent" className="discount-percent">

                                                    {/* <a href="#" className="wave" style={{"bottom": this.state.productOfferObj.discountPercent + "%"}}>
                                                                <span class="liquid-4"></span>
                                                                <span class="liquid-4"></span>
                                                                <span class="liquid-4"></span>
                                                                <span class="liquid-4"></span>
                                                            </a> */}

                                                    <div class="ocean" style={{ "height": (this.state.productOfferObj.discountPercent) / 2 + "%" }}>
                                                        <div class="wave"></div>
                                                        <div class="wave"></div>
                                                    </div>

                                                    <div className="p-col-12 p-lg-12 p-xl-12 discount-percent-number">
                                                        <div>
                                                            <AnimatedNumber component="text" value={this.state.productOfferObj.discountPercent}
                                                                style={{
                                                                    transition: '2s ease-out',
                                                                    fontSize: 48,
                                                                    transitionProperty:
                                                                        'background-color, color, opacity'
                                                                }}
                                                                frameStyle={perc => (
                                                                    perc === 100 ? {} : { backgroundColor: '#ffeb3b00' }
                                                                )}
                                                                duration={5000}
                                                                formatValue={e => this.prettyBytes(e)}
                                                            />%
                                                                </div>
                                                     
                                                        <span className='text-bold'>
                                                            Discount Percentage
                                                                </span>

                                                    </div>

                                                    <img
                                                        className="discount-percent-wave discount-percent-seventy"
                                                        src={discountPercentSeventy}
                                                        style={{ "bottom": this.state.productOfferObj.discountPercent >= 70 ? "0" : "-130px" }}
                                                    />

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="p-col-12 p-xl-4 ">
                        <div className="layout-dashboard">
                            {this.state.dataTableIsLoading ? "" :
                                <WalletBalance
                                    walletBalanceName='Wallet'
                                />
                            }
                        </div>

                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    header={tableHeader}
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    // paginator={true} 
                                    rows={10}
                                >
                                    <Column field="offerUseDate"  className="table-datetime" header="Date & Time" sortable={true} filter={true} />
                                    <Column field="productName" header="Product Name" sortable={true} filter={true} />
                                    <Column field="purchaseInvoiceID" header="Invoice ID" sortable={true} filter={true} />
                                    <Column field="offerCode" header="Offer Code" sortable={true} filter={true} />
                                    <Column field="productQuantity" header="Product Quantity" sortable={true} body={this.productQuantityTemplate}  filter={true} className="col-number" />
                                    <Column field="" header="Total Amount" className="text-right" body={this.totalAmountTemplate} sortable={true} filter={true} />
                                    <Column field="totalDiscount" className="text-right" header="Discount Amount" body={this.discountTemplate} sortable={true} filter={true} />
                                    <Column field="payableAmount" className="text-right" header="Paid Amount" body={this.paidAmountTemplate} sortable={true} filter={true} />
                                    <Column field="" style={{width:"96px"}} header="Details" body={this.purchaseCodeBody} />

                                </DataTable>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>

                    </div>

                </div>

                <Dialog className="nw-dialog" header="Purchase Code Info" visible={this.state.visible} onHide={this.onHide} maximizable>

                    <div className="p-fluid">
                        <div className="p-grid">

                            <div className="p-col-6 p-xl-6">
                                <span>Product Name : {this.state.dialogProductName} </span><br />
                                <span>Product Type :  {this.state.dialogProductType}</span><br />
                                <span>Purchase Point : {this.state.dialogpurchasePoint}</span><br />
                            </div>
                            <div className="p-col-6 p-xl-6">
                                <span>Offer Code : {this.state.dialogOfferCode}</span><br />
                                <span>Date : {this.state.dialogProductDate}</span><br />
                                <span>Quantity: {this.state.dialogProductQuantity} </span><br />
                            </div>

                            {/* <PurchaseCodeInfoTable getPurchaseProductId={this.state.productOfferLogID} /> */}
                            <div className="p-col-12 p-xl-12 nw-data-table">
                                {!this.state.dialogDataTableIsLoading ?
                                    <DataTable
                                        header={'Purchase Code List'}
                                        value={this.state.purchaseCodeListTable}
                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                    >
                                        <Column field="purchaseCode" header="Purchase Code" sortable={true} filter={true} />
                                        <Column field="usedStatus" header="Status" body={this.statusCheck} sortable={true} filter={true} />
                                        <Column field="usedDate" header="Used Date" body={this.usedDateBody} sortable={true} filter={true} />
                                    </DataTable>
                                    : this.NetiContentLoader.MyPointTableLoader()
                                }
                            </div>


                        </div>
                    </div>

                </Dialog>

            </div>

        );
    }
}