import React, { Component } from 'react';

import { Calendar } from 'primereact/calendar';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

let maxDate = new Date();
let selectedModuleType = '';
export class EmImplementationStudentAndGeneralAccounts extends Component {

    constructor(props) {
        super(props);

        this.state = {

            requestedObj: {
                date: '',
                moduleType: '',
            },
            errors: {},
            dataTableValue: [],

        }

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeModuleType = this.onChangeModuleType.bind(this);
        this.clearEmptyErrorMsg = this.clearEmptyErrorMsg.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    onChangeDate(e) {

        let { requestedObj } = this.state;
        requestedObj[e.target.name] = e.target.value;
        this.setState({ requestedObj });
        this.clearEmptyErrorMsg(e.target.name);

    }

    onChangeModuleType(e) {

        let { requestedObj } = this.state;
        requestedObj[e.target.name] = e.target.value;
        this.setState({ requestedObj });
        this.clearEmptyErrorMsg(e.target.name);

    }

    searchHandleError = () => {

        let { errors, requestedObj } = this.state;
        let formIsValid = true;
        // this.setState({ topProgressBar: false });

        if (requestedObj.date === '') {
            formIsValid = false;
            errors["date"] = "Date can't left empty.";
        }
        if (requestedObj.moduleType === '') {
            formIsValid = false;
            errors["moduleType"] = "ModuleType can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearEmptyErrorMsg(name) {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSubmitHandler() {

        let { requestedObj } = this.state;

        if (this.searchHandleError()) {

            console.log('requestedObj', requestedObj);
            let tempDataArr = []
            if (requestedObj.moduleType === 'Student Accounts') {

                 tempDataArr = [
                    { 'customNetiID': 1000000218, 'fullName': 'Mr. Allice', 'basicMobile': '01752142546', 'area': 'Barisal West-Zone-1', 'totalInstitute': 10, 'unsentInstitute': 0 },
                    { 'customNetiID': 1000000219, 'fullName': 'Mr. Bob', 'basicMobile': '01752142546', 'area': 'Barisal West-Zone-1', 'totalInstitute': 12, 'unsentInstitute': 0 },
                ]

            } else {

                 tempDataArr = [
                    { 'customNetiID': 1000000220, 'fullName': 'Mr. Dan', 'basicMobile': '01752142546', 'area': 'Barisal West-Zone-2', 'totalInstitute': 13, 'unsentInstitute': 0 },
                    { 'customNetiID': 1000000221, 'fullName': 'Mr. Golf', 'basicMobile': '01752142546', 'area': 'Barisal West-Zone-2', 'totalInstitute': 14, 'unsentInstitute': 0 },
                ]

            }

            selectedModuleType = requestedObj.moduleType;
            this.setState({ dataTableValue: tempDataArr });

            // this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

        }
    }

    render() {

        let { requestedObj, dataTableValue } = this.state;

        // all dropdown options 
        let moduleOptions = [
            { label: 'Student Accounts', value: 'Student Accounts' },
            { label: 'General Accounts', value: 'General Accounts' },
        ]
        // all dropdown options end

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Partner Wise {selectedModuleType} Report</div>
            <div className="header-title-right"><a href="#">Total Found: {dataTableValue.length}</a></div>
        </div>;

        return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                {/* {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                } */}

                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Date <span>*</span></span>
                                        <Calendar
                                            name='date'
                                            value={requestedObj.date}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["date"]}</span>
                                </div>

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Module Type<span>*</span></span>
                                        <Dropdown
                                            name='moduleType'
                                            value={requestedObj.moduleType}
                                            options={moduleOptions}
                                            onChange={this.onChangeModuleType}
                                            placeholder="Select Module Type"
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["moduleType"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 p-col-nogutter nw-data-table">
                            {/* {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() : <div> */}
                            <DataTable
                                value={dataTableValue}
                                selectionMode="single"
                                header={tableHeader}
                                responsive={true}
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 30]}
                            >
                                <Column field="customNetiID" header="Partner NetiID" sortable={true} filter={true} />
                                <Column field="fullName" header="Partner Name" sortable={true} filter={true} />
                                <Column field="basicMobile" header="Mobile No." sortable={true} filter={true} />
                                <Column field="area" header="Area" sortable={true} filter={true} />
                                <Column field="totalInstitute" header="Total Institute" sortable={true} filter={true} />
                                <Column field="totalInstitute" header="Sent Institute" sortable={true} filter={true} />
                                <Column field="unsentInstitute" header="Not Sent Institute" sortable={true} filter={true} />
                            </DataTable>
                        </div>

                    </div>
                </div>
            </div>

        )

    }
}