import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';

export class TeacherBankInfo extends Component {

    constructor(props) {
        super(props);
        this.teacherPortal = new TeacherPortal();
    }

    bankStatusTemplate(rowData) {
        return rowData ? "Active" : "Inactive";
    }

    render() {

        let TeacherBankContent = ''
        if (this.props.basicInfo.item) {
            console.log(this.props.basicInfo.item);
            if (this.props.basicInfo.item.staffBankAccount) {
                let i = 0;
                TeacherBankContent = this.props.basicInfo.item.staffBankAccount.map((item) => {

                    i++
                    return (
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2" >
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Bank Account {i}</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Name</h4>
                                        <p>{item.accountName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Account No.</h4>
                                        <p>{item.accountNumber || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Type</h4>
                                        <p>{item.accountType || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Bank</h4>
                                        <p>{item.bankName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Branch</h4>
                                        <p>{item.branch || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Status</h4>
                                        <p>{item.accountStatus || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )



                }


                )
            }


        }
        return TeacherBankContent
    }
}