import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import {DataTable} from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import {Column} from 'primereact/column';
import {Row} from 'primereact/row';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import {ColumnGroup} from 'primereact/columngroup';
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { UserCategoryService } from "../../../service/UserCategoryService";
import NetiContentLoader from '../../common/NetiContentLoader';
import { pdfDownloadLandscape_TableWithSubTitle } from '../../common/JsPdfDownload';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';


let urlId;
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
export class AdmisiaApplicantAnalytics extends Component {
    constructor() {
        super();
        this.state = {
            errors:{},
            academicYear:'',
            academicYearErr:{},
            academicYearList:[],
            dataTableIsLoading: false,
            applicantFormVisibility: false,
            errorMsgVisible:false,
            topProgressBar: false,
            dataTableIsLoading:false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,
            dataTableValue: []
        };

        this.admisiaPointService = new AdmisiaPointService();
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentDidMount();
            }, 1000);
        }
    }
    

    componentDidMount = () => {
        urlId=this.admisiaPointService.getUrlInfoFromLocalStorage();
        this.getAcademicYearListByTypeID();
    }

    getAcademicYearListByTypeID = () =>{
        this.setState({ topProgressBar: true, errorMsgVisible: false, firstDropdownIsLoading: true});

        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ academicYearList: body});
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgVisible: true, errorMsgBody: responseBody,  firstDropdownIsLoading: false  })
                });
            }
            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false, errorMsgBody: 'Unable to load data. Please check connection' })
                
            });
    }

    onChangeAcademicYear = (e) => {
        let { academicYearErr } = this.state;
        academicYearErr["academicYear"] = '';
		this.setState({academicYear: e.target.value});
    }

    onSearchHandler = () => {
        if (this.searchAcademicYearError()) {
        this.setState({ topProgressBar: true,  dataTableIsLoading:true, errorMsgVisible: false, errorMsgVisible: false  });
        this.admisiaPointService.getApplicantAnalyticsReport(urlId,this.state.academicYear)
        .then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    this.setState({ dataTableValue: body});
                    this.setState({ dataTableIsLoading:false, errorMsgVisible: false, topProgressBar: false  });
                });
            } else {
                this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading:false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading:false, errorMsgVisible: false, errorMsgBody: 'Unable to load data. Please check connection'});
           
        });
      }

    }

    searchAcademicYearError = () => {
        let { academicYearErr } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (this.state.academicYear === '') {
            formIsValid = false;
            academicYearErr["academicYear"] = 'Academic Year can not left empty.';
        }

        this.setState({ academicYearErr });
        return formIsValid;
    }

    export = () => { 
        this.dt.exportCSV();
    }

    exportPdf = () => {

        let tableColumns = [
            { title: "Class", dataKey: "class" },
            { title: "Group", dataKey: "group" },
            { title: "Total Applicant", dataKey: "totalApplicant" },
            { title: "Assis Pending", dataKey: "assisPending" },
            { title: "Assis Rejected", dataKey: "assisRejected" },
            { title: "Assis Approved", dataKey: "assisApproved" },
            { title: "Ad Pending", dataKey: "adPending" },
            { title: "Ad Waiting", dataKey: "adWaiting" },
            { title: "Ad Rejected", dataKey: "adRejected" },
            { title: "Ad Approved", dataKey: "adApproved" },
            { title: "Transfered", dataKey: "transfered" },
          ];

          pdfDownloadLandscape_TableWithSubTitle("Applicant Analytics Report",pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, "applicant_analytics_Report.pdf");

    }
   
    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let academicYear=[];
        if ((this.state.academicYearList != null) && (this.state.academicYearList.length > 0)) {
            academicYear = this.state.academicYearList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                        Applicant Analytics List
                        <span
                            style={{ color: "white", display: "flex", float: "right" }}
                        >
                            Total Found: {this.state.dataTableValue.length}
                        </span>
                    </div>;

        let headerGroup = <ColumnGroup>
            <Row>
                <Column header="Class" rowSpan={3} />
                <Column header="Group" rowSpan={3} />
                <Column header="Total Applicant" rowSpan={3} />
                <Column header="Assessment" colSpan={3} />
                <Column header="Admission" colSpan={4} />
            </Row>
            <Row>
                <Column header="Pending" />
                <Column header="Rejected" />
                <Column header="Approved" />
                <Column header="Pending" />
                <Column header="Waiting" />
                <Column header="Rejected" />
                <Column header="Approved" />
                <Column header="Transferred" />
            </Row>
        </ColumnGroup>;

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" }
        ];
        pdfTitleHeaderData = [
        { A: "Year", B: this.state.academicYear}
        ];
      
        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-xl-12"> 
                                <div className="p-grid nw-form-body"> 
                                    
                                    <div className="p-col-12 p-xl-10">
                                    { this.state.firstDropdownIsLoading ?   
                                        this.NetiContentLoader.normalFormInputField():
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Academic year</span>
                                                <Dropdown
                                                    placeholder="Select Academic Year"
                                                    value={this.state.academicYear}
                                                    options={academicYear}
                                                    onChange={this.onChangeAcademicYear}
                                                    showClear={false}
                                                    keyfilter="pint"
                                                    autoWidth={false}
                                                    name="academicYear"
                                                />
                                        </div>
                                    }
                                        <span className="error-message">{this.state.academicYearErr["academicYear"]}</span>
                                    </div> 
                                        

                                    <div className="p-col-12 p-xl-2 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-left"
                                            label="Search"
                                            icon="fas fa-search"
                                            onClick={this.onSearchHandler}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                       
                  
                     <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                        { this.state.dataTableIsLoading ? 
                          this.NetiContentLoader.MyPointTableLoader():
                         
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    activeIndex="0"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    headerColumnGroup={headerGroup}
                                    ref={(el) => { this.dt = el; }}

                                >
                                    <Column field="class" />
                                    <Column field="group" />
                                    <Column field="totalApplicant" />
                                    <Column field="assisPending" />
                                    <Column field="assisRejected" />
                                    <Column field="assisApproved" />
                                    <Column field="adPending" />
                                    <Column field="adWaiting" />
                                    <Column field="adRejected" />
                                    <Column field="adApproved" />
                                    <Column field="transfered" />
                                </DataTable>
                             }
                               
                        </div>

                        

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                <Button
                                    label="Download CSV"
                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                    icon="fas fa-arrow-down"
                                    onClick={this.export}
                                />
                                <Button
                                    label="Download PDF"
                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                    icon="fas fa-arrow-down"
                                    onClick={this.exportPdf}
                                />
                            </div>
                        </div>

                    </div>
                 
              </div>
            </div>
        );
    }
}