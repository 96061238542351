import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { TreeTable } from 'primereact/treetable';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Workbook } from 'react-excel-workbook';
import { Button } from 'primereact/button';

let assignedPartnerListArr = [];
let MbpPartnerListArr = [];
export class ManagementPointPartnerTree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assignedPartnerList: [],
            dataTableIsLoading: false,
            mbpListObj: {},
            zbpListObj: {},
            bdpListObj: {},
            assignListObj: {},
            assignListArr: [],
            expandedKeys: {},
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            first: 0,
            rows: 10,
            totalRecords: 0,
            loading: false,
            excelTableData: []
        }

        this.managementPointService = new ManagementPointService;
        this.onExpand = this.onExpand.bind(this);
        this.NetiContentLoader = new NetiContentLoader();

    }

    componentWillMount() {
        this.viewAssignedPartnerList();
    }

    viewAssignedPartnerList = () => {

        let { assignListObj } = this.state;
        assignedPartnerListArr = [];
        this.setState({ assignListObj: {} });
        this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: true });
        this.managementPointService.findMbpTreeForAdmin().then(res => {
            if (res.status === 302) {
                return res.json().then((body) => {

                    this.setState({ excelTableData: body }); // for excel
                    body.map((item, i) => {
                        assignListObj.data = item;
                        assignListObj.key = i;
                        assignListObj.children = [];
                        assignListObj.leaf = false;
                        assignedPartnerListArr.push(Object.assign({}, assignListObj));
                    });

                    this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false });
                })
            } else {
                this.managementPointService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp, dataTableIsLoading: false })
                    });
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    onExpand(event) {
        let { mbpListObj, zbpListObj, bdpListObj } = this.state;
        this.setState({ mbpListObj: {} });
        this.setState({ zbpListObj: {} });
        this.setState({ bdpListObj: {} });

        let lazyNode = { ...event.node };

        if (lazyNode.data.designationDefaultCode === "T1120202") {
            if (lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.managementPointService.findPartnerTreeInfoForAdmin('T1120203', lazyNode.data.partnerAssignID).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                mbpListObj.data = item;
                                mbpListObj.key = `${lazyNode['key']}-${i}`;
                                mbpListObj.children = [];
                                mbpListObj.leaf = false;
                                lazyNode.children.push(Object.assign({}, mbpListObj));
                            });

                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.managementPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
        }

        else if (lazyNode.data.designationDefaultCode === "T1120203") {
            if (lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.managementPointService.findPartnerTreeInfoForAdmin('T1120204', lazyNode.data.partnerAssignID).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                zbpListObj.data = item;
                                zbpListObj.key = `${lazyNode['key']}-${i}`;
                                zbpListObj.children = [];
                                zbpListObj.leaf = false;
                                lazyNode.children.push(Object.assign({}, zbpListObj));
                            });

                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.managementPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
        }

        else if (lazyNode.data.designationDefaultCode === 'T1120204') {
            if (lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.managementPointService.findPartnerTreeInfoForAdmin('T1120205', lazyNode.data.partnerAssignID).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                bdpListObj.data = item;
                                bdpListObj.key = `${lazyNode['key']}-${i}`;
                                lazyNode.children.push(Object.assign({}, bdpListObj));
                            });

                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.managementPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
        }

    }

    onExpandToggle = (e) => {
        console.log("on toggle", e);

        this.setState({ expandedKeys: e.value })

        // let expandedKeys = {...e.value};
        //     console.log("on toggle expandedKeys",expandedKeys);
        // this.setState({expandedKeys: expandedKeys});
    }

    onChangeStatus = (rowData) => {
        return (<span>{rowData.data["partnerStatus"] === 1 ? "Active" : "Inactive"}</span>);

    }

    render() {

        console.log("assignedPartnerListArr", assignedPartnerListArr);
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Assigned Partner List</div>;

        // excel
        let excelColumns = "";
        let excelFileName = "Assigned_Partner_Tree.xlsx";
        let excelSheetName = "Assigned_Partner_Tree";
        excelColumns = [
            <Workbook.Column value="partnerArea" label="Area" />,
            <Workbook.Column value="partnerName" label="Name" />,
            <Workbook.Column value="partnerNetiID" label="Neti ID" />,
            <Workbook.Column value="partnerMobile" label="Mobile No." />,
            <Workbook.Column value="partnerStatus" label="Partner Status" />,
            <Workbook.Column value="partnerDesignation" label="Partner Type" />,

        ];
        // end excel

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="nw-data-table">
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                {this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : <div>
                                        <TreeTable
                                            header={header}
                                            lazy={true}
                                            value={assignedPartnerListArr}
                                            onExpand={this.onExpand}
                                            expandedKeys={this.state.expandedKeys}
                                            onToggle={this.onExpandToggle}
                                            style={{ marginTop: '.5em' }}>
                                            <Column field="partnerArea" header="Area" expander />
                                            <Column field="partnerName" header="Name" />
                                            <Column field="partnerNetiID" header="Neti ID" />
                                            <Column field="partnerMobile" header="Mobile No." />
                                            <Column field="partnerStatus" body={this.onChangeStatus} header="Status" />
                                            <Column field="partnerDesignation" header="Partner Type" />
                                        </TreeTable>
                                      
                                        {/* <div className="nw-button-parent m-t-8 p-r-0" style={{ float: "right" }}>
                                            <Workbook
                                                filename={excelFileName}
                                                element={
                                                    <Button
                                                        label="Download Excel"
                                                        icon="fas fa-download"
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                    />
                                                }
                                            >
                                                <Workbook.Sheet data={this.state.excelTableData} name={excelSheetName}>
                                                    {excelColumns}
                                                </Workbook.Sheet>
                                            </Workbook>
                                        </div> */}
                                        
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}