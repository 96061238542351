import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import CommonFuctionality from "../common/CommonFuctionality";
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { UserCategoryService } from '../../service/UserCategoryService';
import { AdminPointService } from '../../service/adminPoint/AdminPointService';
import { Paginator } from 'primereact/paginator';

let errors = {};
export class UpdateInventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productTypeList: "",
            searchDataObj: {
                pageLimit: 10,
                pageNo: 0
            },
            first: 0, 
            rows: 10, 
            productList: "",
            dataTableValue: [],
            selectedRow: {
                productInfoDTO: {
                    productID: '',
                    productTypeInfoDTO: { coreCategoryID: "" }
                }
            },
            visible: false,
            errors: {}
        };

        this.fetchProductListFromInventory = this.fetchProductListFromInventory.bind(this);
        this.fetchProductTypeList = this.fetchProductTypeList.bind(this);
        this.fetchProductList = this.fetchProductList.bind(this);
        this.fetchProductListByInventoryID = this.fetchProductListByInventoryID.bind(this);

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);


        this.crmPointService = new CRMPointService();
        this.userCategoryService = new UserCategoryService();
        this.adminPointService = new AdminPointService();
        this.CommonFuctionality = new CommonFuctionality();

        this.onChangeProductType = this.onChangeProductType.bind(this);
        this.onChangeProductName = this.onChangeProductName.bind(this);
        this.onChangeProductID = this.onChangeProductID.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.submitUpdate = this.submitUpdate.bind(this);
    }

    componentWillMount() {
        this.fetchProductListFromInventory()
    }

    // onPageChange(event) {

    //     console.log("event", event);
        
    //     let { searchDataObj } = this.state
    //     searchDataObj.pageNo = event.page

    //     this.setState({
    //         first: event.first,
    //         rows: event.rows
    //     });

    //     this.fetchProductListFromInventory();
        
    // }
    viewDialog(rowData) {
        this.setState({
            selectedRow: {
                productInfoDTO: {
                    productID: '',
                    productTypeInfoDTO: { coreCategoryID: "" }
                }
            }
        })
        this.fetchProductListByInventoryID(rowData.productInventoryID);
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.fetchProductTypeList('T104');
        this.setState({ error: {}, visible: true });
        
    }
    onHide() {
        this.setState({ visible: false })
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    fetchProductListFromInventory() {

        this.crmPointService.fetchProductListFromInventory(this.state.searchDataObj)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].tagStatus === 1) {
                                body[i].tagStatus = 'Tagged';
                            } else if(body[i].tagStatus === 0) {
                                body[i].tagStatus = 'Untagged';
                            }
                        }
                        console.log('inventory list', body)
                        this.setState({ dataTableValue: body })
                    });
                } else { return res.json().then((errorBody) => console.log('ErrorBody', errorBody)) }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchProductTypeList(defaultCode) {

        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productTypeList: body });
                    })
                } else { return res.json().then((errorBody) => console.log('ErrorBody', errorBody)) }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchProductList(enableStatus, productTypeId) {
        let productTypeInfoDTO = {
            "coreCategoryID": productTypeId,
        }
        this.adminPointService.fetchProductListByProductType(enableStatus, productTypeInfoDTO)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body });
                    })
                } else { return res.json().then((errorBody) => console.log('ErrorBody', errorBody)) }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchProductListByInventoryID(productInventoryID) {
        this.crmPointService.fetchProductListFromInventoryByInventoryID(productInventoryID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => { this.setState({ selectedRow: body }); this.fetchProductList(1, body.productInfoDTO.productTypeInfoDTO.coreCategoryID); })
                } else { return res.json().then((errorBody) => console.log('ErrorBody', errorBody)) }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeProductType(value) {

        this.fetchProductList(1, value);
        errors["productType"] = "";
        let { selectedRow } = this.state;
        selectedRow.productInfoDTO.productTypeInfoDTO.coreCategoryID = value;
        this.setState({ selectedRow, errors: errors });

    }

    onChangeProductName(value) {

        errors["productName"] = "";
        let { selectedRow } = this.state;
        selectedRow.productInfoDTO.productID = parseInt(value);
        this.setState({ selectedRow, errors: errors });

    }

    onChangeProductID(value) {
        errors["productUniqueID"] = "";
        let { selectedRow } = this.state;
        selectedRow.productUniqueID = value;
        this.setState({ selectedRow, errors: errors });

    }

    onChangeNote(value) {
        let { selectedRow } = this.state;
        selectedRow.inventoryNote = value;
        this.setState({ selectedRow });

    }

    submitUpdate() {
        if (this.handleUpdateError()) {
            let { selectedRow } = this.state;
            this.crmPointService.updateProductInfoFromInventory(selectedRow)
                .then(res => {
                    if (res.status == 302) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data updated successfully.' });
                        this.setState({ visible: false });
                        this.fetchProductListFromInventory();
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                    } else { return res.json().then((errorBody) => console.log('ErrorBody', errorBody)) }
                })
        }

    }

    handleUpdateError() {

        errors = {};
        let formIsValid = true;
        let { selectedRow } = this.state;

        if (!selectedRow.productInfoDTO.productTypeInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["productType"] = "Product Type can't left empty.";
        }
        if (!selectedRow.productInfoDTO.productID) {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }
        if (!selectedRow.productUniqueID) {
            formIsValid = false;
            errors["productID"] = "Product Unique ID can't left empty.";
        } 
        // if (!selectedRow.inventoryNote) {
        //     formIsValid = false;
        //     errors["inventoryNote"] = "Note can't left empty.";
        // }
        else {
            return formIsValid;
        }
        this.setState({ errors: errors });
    }

    render() {

        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Product Inventory List
        <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue.length} </span>
        </div>;



        let productTypeOptions = [];
        if (this.state.productTypeList && this.state.productTypeList.length) {
            productTypeOptions = this.state.productTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let productListOptions = [];
        if (this.state.productList && this.state.productList.length) {
            productListOptions = this.state.productList.map(item => ({
                value: item.productID,
                label: item.productName
            }));
        }

        let { selectedRow } = this.state;
        let editBody = (rowData) => {

            return <div className='text-center'>
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-edit"
                    tooltip="Update"
                    onClick={(e) => this.viewDialog(rowData)}
                />
            </div>
        }

        return (

            <div className="p-fluid">
                <div className="p-grid">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="blur-section">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table blur-section">
                                <DataTable
                                    header={tableHeader}
                                    value={this.state.dataTableValue}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    rowsPerPageOptions={[10, 20, 30]}


                                >
                                    <Column field="productType" header="Product Type" filter={true} />
                                    <Column field="productName" header="Product Name" filter={true} />
                                    <Column field="productUniqueID" header="Product ID" filter={true} />
                                    <Column field="tagStatus" header="Use Status" filter={true} />
                                    <Column field="" header="Action" style={{ width: "80px" }} body={editBody} />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div className="dialog-section">
                    <Dialog className="nw-dialog" header="Update Product Inventory Information " visible={this.state.visible} onHide={this.onHide}>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                   <div className="p-grid nw-form">
                                      <div className="p-col-12 p-xl-12">
                                         <div className="nw-form-body">
                                            {this.state.visible == true ?
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <div className="formControl">
                                                            <label>Product Type <span>*</span></label>
                                                            <Dropdown
                                                                placeholder='Select Product Type'
                                                                name="productType"
                                                                options={productTypeOptions}
                                                                value={selectedRow.productInfoDTO.productTypeInfoDTO.coreCategoryID || ''}
                                                                onChange={(e) => this.onChangeProductType(e.target.value)}
                                                                filter={true}
                                                                autoWidth={false}
                                                            />
                                                            <span className='error-message'>{this.state.errors["productType"]}</span>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12">
                                                        <div className="formControl">
                                                            <label>Product Name <span>*</span></label>
                                                            <Dropdown
                                                                placeholder='Select Product Name'
                                                                name="productName"
                                                                options={productListOptions}
                                                                value={selectedRow.productInfoDTO.productID || ''}
                                                                onChange={(e) => this.onChangeProductName(e.target.value)}
                                                                filter={true}
                                                                autoWidth={false}
                                                            />
                                                            <span className='error-message'>{this.state.errors["productName"]}</span>
                                                        </div>

                                                    </div>

                                                    <div className="p-col-12">
                                                        <div className="formControl">
                                                            <label>Product Unique ID <span>*</span></label>
                                                            <InputText
                                                                name='productID'
                                                                placeholder='Enter Unique ID'
                                                                value={selectedRow.productUniqueID || ''}
                                                                onChange={(e) => this.onChangeProductID(e.target.value)}
                                                                autoWidth={false}
                                                                disabled={ selectedRow.tagStatus === 1 ? true : false }
                                                                
                                                            />
                                                            <span className='error-message'>{this.state.errors["productID"]}</span>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12">
                                                        <div className="formControl">
                                                            <label>Note</label>
                                                            <InputTextarea
                                                                name='inventoryNote'
                                                                placeholder='Enter Note'
                                                                value={selectedRow.inventoryNote || ''}
                                                                onChange={(e) => this.onChangeNote(e.target.value)}
                                                                autoWidth={false}
                                                            />
                                                            {/* <span className='error-message'>{this.state.errors["inventoryNote"]}</span> */}
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                        <Button
                                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                                            label="Discard"
                                                            icon="fas fa-times"
                                                            onClick={event => this.onHide(event)}
                                                        />
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                                            label="Update"
                                                            icon="far fa-save"
                                                            onClick={event => this.submitUpdate(event)}
                                                        />
                                                    </div>
                                                </div>
                                                : ''}
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    </div>
                </div>
            </div>

        )
    }
}