import React, {Component} from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {CollectiveStatementPaid} from './CollectiveStatementPaid';
import {CollectiveStatementDiscount} from './CollectiveStatementDiscount';

export class CollectiveStatementMain extends Component {

    constructor(props) {
		super(props);
		this.state = {
        }
    }
        
    render() {

        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView>
                            <TabPanel header="Paid" >
                                <CollectiveStatementPaid/>
                            </TabPanel>
                            <TabPanel header="Discount">
                                <CollectiveStatementDiscount/>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}