import { MY_POINT, EM_USER_POINT } from "./PointWiseRouteConsts";

export const MyPointMenu = {
  // label: 'My Point', icon: 'fas fa-sitemap',

  items: [
    {
      label: "My Point",
      icon: "fas fa-sitemap",

      items: [
        {
          label: "Startup",
          icon: "", //pi pi-fw pi-dollar
          items: [
            { label: "My Profile", icon: "", to: MY_POINT.MY_PROFILE }, //pi pi-fw pi-money-bill
            { label: "Add Point", icon: "", to: EM_USER_POINT.ADD_POINT }, //pi pi-fw pi-envelope
            { label: "NetiMail", icon: "", to: "/chat_box" }, //pi pi-fw pi-envelope
            { label: "Support Token", icon: "", to: MY_POINT.NEW_TOKEN }, //pi pi-fw pi-envelope
            // { label: 'Meeting Call', icon: '', to: MY_POINT.MEETING_CALL }, //pi pi-fw pi-money-bill
          ],
        },
        // { label: 'Dashboard', icon: '', to: MY_POINT.DASHBORAD },//pi pi-fw pi-home
        {
          label: "Balance",
          icon: "", //pi pi-fw pi-dollar
          items: [
            { label: "Wallet", icon: "", to: MY_POINT.WALLET2 }, //pi pi-fw pi-money-bill
            { label: "Message", icon: "", to: MY_POINT.MESSAGE }, //pi pi-fw pi-envelope
          ],
        },

        {
          label: "Purchase",
          icon: "", //pi pi-fw pi-money-bill
          items: [
            {
              label: "General Product",
              icon: "",
              to: MY_POINT.GENERAL_PRODUCT,
            }, //pi pi-fw pi-tag
            { label: "Offer Product", icon: "", to: MY_POINT.OFFER_PRODUCT }, //pi pi-fw pi-tags
          ],
        },

        {
          label: "Sell", //pi pi-fw pi-bookmark
          items: [
            { label: "Sell Product", icon: "", to: MY_POINT.SELL_PRODUCT }, //pi pi-fw pi-money-bill
            // Remove Customer Info menu from menu List
            // { label: 'Customer Info', icon: '', to: MY_POINT.CUSTOMER_INFO }, //pi pi-fw pi-envelope
          ],
        },

        { label: "Bank Account", icon: "", to: MY_POINT.USER_BANK_ACCOUNT }, //pi pi-fw pi-home

        {
          label: "Reports",
        },

        {
          label: "Balance",
          icon: "", //pi pi-fw pi-dollar
          items: [
            { label: "Wallet Log", icon: "", to: MY_POINT.WALLET_LOGS }, //pi pi-fw pi-money-bill
            { label: "Statement", icon: "", to: MY_POINT.BALANCE_STATEMENT },
            {
              label: "Revenue Log",
              icon: "",
              to: MY_POINT.PARTNER_REVENUE_LOG,
            },
            { label: "Message Log", icon: "", to: MY_POINT.MESSAGE_LOGS }, //pi pi-fw pi-envelope
          ],
        },

        {
          label: "Purchase",
          icon: "", //pi pi-fw pi-money-bill
          items: [
            {
              label: "General Product",
              icon: "",
              to: MY_POINT.PURCHASE_LOG_GENERAL,
            }, //pi pi-fw pi-money-bill
            {
              label: "Offer Product",
              icon: "",
              to: MY_POINT.PURCHASE_LOG_OFFER,
            }, //pi pi-fw pi-money-bill
          ],
        },

        {
          label: "Purchase Code",
          icon: "", //pi pi-fw pi-money-bill
          items: [
            // { label: 'Code Log', icon: '', to: MY_POINT.PURCHASE_CODE_LOG }, //pi pi-fw pi-home
            {
              label: "Unused Code",
              icon: "",
              to: MY_POINT.UNUSED_PURCHASE_CODE_LOG,
            }, //pi pi-fw pi-home
            {
              label: "Used Code",
              icon: "",
              to: MY_POINT.USED_PURCHASE_CODE_LOG,
            }, //pi pi-fw pi-home
          ],
        },

        {
          label: "Sell",
          icon: "", //pi pi-fw pi-money-bill
          items: [
            { label: "Sell Product", icon: "", to: MY_POINT.SALES_PRODUCT_LOG }, //pi pi-fw pi-bookmark
            // Remove Customer Info menu(Report) from menu List
            // { label: 'Customer Info', icon: '', to: MY_POINT.CUSTOMER_INFO_LOG }, //pi pi-fw pi-money-bill
          ],
        },
      ],
    },
  ],
};
